<div class="main_div">
  <div style="margin-bottom: 32px">
    <span class="colored">Home </span> / Employee Engagement
  </div>
  <div class="main_body">
    <div class="main_body_title">Employee Engagement</div>

    <div class="overview_carts">
      <div *ngFor="let carts of overviewCarts" class="carts">
        <div class="upper_part">
          <div class="left_side">
            <div class="carts_title">{{ carts.carts_name }}</div>
            <div class="carts_description">{{ carts.carts_description }}</div>
          </div>
          <div class="right_side">
            <div class="carts_img">
              <img loading="lazy" [src]="carts.carts_icon" alt="" />
            </div>
          </div>
        </div>
        <div class="total_amount">
          <span style="color: #0054a6" *ngIf="carts.isAmount">৳</span>
          {{ carts.calculated_data | number
          }}<span *ngIf="carts.isRate">%</span>
        </div>
      </div>
    </div>

    <div class="main_body_title">Employee Leaderboard</div>

    <div class="donor_div">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="leaderBoard"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="true"
      >
        <dxo-paging [pageSize]="7"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="false"
          allowedPageSizes="10"
          [showInfo]="true"
        >
        </dxo-pager>
        <dxi-column
          [width]="'90px'"
          caption="Rank"
          dataField="rank"
        ></dxi-column>
        <dxi-column
          [width]="'23%'"
          caption="Employee Name"
          dataField="employee_name"
        ></dxi-column>
        <dxi-column [width]="'20%'" caption="Department" dataField="Department">
        </dxi-column>
        <dxi-column
          [width]="'23%'"
          caption="Contributions"
          dataField="activity_Type"
        >
        </dxi-column>
        <dxi-column
          [width]="'18%'"
          caption="Campaigns Participate"
          dataField="description"
        >
        </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row">
            <td style="text-align: left">
              <div class="employee_rank">
                @if(row?.rowIndex < 3){
                <img
                  loading="lazy"
                  [src]="
                    './assets/images/icon/trophy_' + row?.rowIndex + '.svg'
                  "
                  alt="rank"
                />
                }@else {
                <div class="trophy_img_" style="margin-left: 8px">
                  {{ row?.rowIndex + 1 }}
                </div>
                }
              </div>
            </td>
            <td>
              <!-- [routerLink]="'/corporate/employee-information'" -->
              <div
                class="combine cursor"
                [routerLink]="'/corporate/employee-information'"
                [queryParams]="{ id: row?.data?.user_id }"
              >
                <div class="employee_profile">
                  <img
                    loading="lazy"
                    [src]="
                      row?.data?.profile_img?.url ||
                      './assets/images/round_profile.png'
                    "
                    alt="PROFILE"
                  />
                </div>
                <div class="normal_data" style="padding-top: 5px">
                  {{ row?.data?.name }}
                </div>
              </div>
            </td>
            <td>
              <div class="normal_data">{{ row.data?.department_name }}</div>
            </td>
            <td>
              <div class="normal_data">
                ৳ {{ row.data?.total_amount | number }}
              </div>
            </td>
            <td>
              <div class="combine">
                <div class="employee_rank multiple_icon">
                  <img
                    loading="lazy"
                    src="./assets/images/icon/mic_announcement.svg"
                    alt="rank"
                  />
                </div>
                <div class="normal_data" style="color: #0054a6">
                  ({{ row.data?.campaign_ids?.length | number }})
                </div>
              </div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
  </div>
</div>
