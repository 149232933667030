import { PaymentTypeEnum } from "../../enum/payment-type.enum";

export class CorporateDepartmentModel {
  company_id: string = null;
  company_name: string = null;
  department_name: number = null;
  department_description: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}