import { CorporateCampaignService } from './../../../../shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { catchError, of } from 'rxjs';
import { ApprovalStatusEnum } from 'src/app/shared/enum/approval-category.enum';
import { CampaignStatusEnum } from 'src/app/shared/enum/campaign-status.enum';
import { FileService } from 'src/app/shared/services/file/file.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-corporate-campaign-list',
  templateUrl: './corporate-campaign-list.component.html',
  styleUrl: './corporate-campaign-list.component.scss',
})
export class CorporateCampaignListComponent {
  public campaignStatusEnum = CampaignStatusEnum;
  public approvalStatusEnum = ApprovalStatusEnum;

  public activeTab = 0;
  public searchKeyword = '';

  public sharePopUp = false;
  public url = '';

  public allCampaign: any = [];
  public filteredCampaign: any = [];

  private companyId: string;

  constructor(
    private readonly router: Router,
    private readonly fileService: FileService,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {}

  async ngOnInit() {
    this.companyId = this.localStorageService.getSessionUser()?.company_id;
    if (this.companyId) {
      this.getAllCampaign();
    }
  }

  private getAllCampaign() {
    this.allCampaign = [];
    this.corporateCampaignService
      .getCorporateCampaignsByCompany(this.companyId)
      .subscribe({
        next: (result) => {
          this.allCampaign = result;
          this.filteredCampaign = result;
        },
      });
  }

  public buttonClick(e: any, campgn) {
    let campaign = JSON.parse(JSON.stringify(campgn));
    campaign.cover_img_vdo = JSON.stringify(campaign.cover_img_vdo);
    switch (e.itemData.id) {
      case 'start_campaign':
        this.startCampaign(campaign.id);
        break;
      case 'end_campaign':
        this.stopCampaign(campaign.id);
        break;
      case 'edit_campaign':
        this.navigateToCreateCampaign(campaign);
        break;
      case 'delete_campaign':
        this.deleteCampaign(campaign);
        break;
      case 'share_campaign':
        this.shareCampaign(campaign.ngo_id, campaign.project_id, campaign.id);
        break;
      default:
        break;
    }
  }

  private async startCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to start the campaign?',
      'Yes, Start'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();
      this.corporateCampaignService
        .startCorporateCampaign(campaignId, currentDate)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign started successfully!'
            );
            this.updateCampaignStatus(campaignId, CampaignStatusEnum.RUNNING);
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error starting the campaign'
            );
          },
        });
    }
  }

  private async stopCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to stop the campaign?',
      'Yes, Stop'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();
      this.corporateCampaignService
        .stopCorporateCampaign(campaignId, currentDate)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign stopped successfully!'
            );
            this.updateCampaignStatus(campaignId, CampaignStatusEnum.STOPPED);
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error stopped the campaign'
            );
          },
        });
    }
  }

  private async deleteCampaign(campaign: any) {
    if (campaign.status === CampaignStatusEnum.RUNNING) {
      this.utilitiesService.showSwal(
        'error',
        'Stop the campaign first',
        'You need to stop the campaign in order to delete'
      );
    } else {
      const response = await this.confirmAction(
        'You want to delete the campaign?',
        'Yes, Delete'
      );
      if (response.isConfirmed) {
        this.spinner.show();
        this.corporateCampaignService.deleteCampaign(campaign.id).subscribe({
          next: (res) => {
            this.onSuccessDeleteCampaign(res, campaign);
          },
          error: (err) => {
            this.onErrorDeleteCampaign(err);
          },
        });
      }
    }
  }

  private onSuccessDeleteCampaign(res: any, campaign: any) {
    this.spinner.hide();
    this.utilitiesService.showSwal('success', 'Campaign deleted successfully!');
    this.allCampaign = this.allCampaign.filter(
      (item) => item.id !== campaign.id
    );
    this.filteredCampaign = this.filteredCampaign.filter(
      (item) => item.id !== campaign.id
    );
    this.deleteFile(campaign);
  }

  private onErrorDeleteCampaign(err: any) {
    this.spinner.hide();
    this.utilitiesService.showSwal(
      'error',
      err?.error?.message || 'Error deleting the campaign'
    );
  }

  private deleteFile(campaign: any): void {
    let file = campaign.cover_img_vdo;
    this.utilitiesService.showSpinner(true);

    this.fileService
      .deleteFile(file.file_id)
      .pipe(
        catchError((error) => {
          return of({ success: false, fileId: file.file_id });
        })
      )
      .subscribe({
        next: (result: any) => {
          this.utilitiesService.showSpinner(false);
          if (result && result.success === false) {
            this.utilitiesService.showSwal(
              'error',
              'Failed to delete file.',
              `File id: ${result.fileId}`
            );
          } else {
            this.utilitiesService.showSwal(
              'success',
              'File deleted successfully.'
            );
          }
        },
        error: (err) => {
          this.utilitiesService.showSpinner(false);
          this.utilitiesService.showSwal('error', 'Failed to delete file.');
        },
      });
  }

  public stopClickEvent(event: MouseEvent) {
    event.stopPropagation();
  }

  private updateCampaignStatus(campaignId: string, status: string) {
    this.allCampaign = this.allCampaign.map((campaign) =>
      campaign.id === campaignId ? { ...campaign, status } : campaign
    );

    this.filteredCampaign = this.filteredCampaign.map((campaign) =>
      campaign.id === campaignId ? { ...campaign, status } : campaign
    );
  }

  public get activeDataSource() {
    const filterDataSource = this.getCurrentDataSource();
    return this.filterDataSource(filterDataSource);
  }

  private getCurrentDataSource() {
    switch (this.activeTab) {
      case 0:
        return this.allCampaign.filter(
          (cmp) => cmp?.status === CampaignStatusEnum.RUNNING
        );
      case 1:
        return this.allCampaign.filter(
          (cmp) => cmp?.status === CampaignStatusEnum.STOPPED
        );
      default:
        return [];
    }
  }

  private filterDataSource(dataSource: any[]) {
    if (!this.searchKeyword) {
      return dataSource;
    }
    const keyword = this.searchKeyword.toLowerCase();
    return dataSource.filter(
      (campaign) =>
        campaign.title.toLowerCase().includes(keyword) ||
        campaign.subtitle.toLowerCase().includes(keyword)
    );
  }

  public onTabChange(event: any) {
    this.activeTab = event.component.option('selectedIndex');
  }

  public onSearchKeywordChanged(event: any) {
    this.searchKeyword = event.event.target.value;
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }

  private shareCampaign(ngoId: string, projectId: string, campaignId: string) {
    this.sharePopUp = true;
    this.generateUrl(ngoId, projectId, campaignId);
  }

  private generateUrl(ngoId: string, projectId: string, campaignId: string) {
    this.url = `https://shohay.net/campaign?ngo_id=${ngoId}&project_id=${projectId}&id=${campaignId}`;
  }

  public copyText(): void {
    navigator.clipboard.writeText(this.url).then(() => {
      this.utilitiesService.showSwalWithToast(
        `URL Copied`,
        `You copied the url`,
        'success'
      );
    });
  }

  public shareOnSocialMedia(socialMedia: string) {
    let shareUrl = '';

    switch (socialMedia) {
      case 'facebook':
        shareUrl = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'linkedin':
        shareUrl = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'whatsapp':
        shareUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          this.url
        )}`;
        break;
      case 'twitter':
        shareUrl = `https://x.com/intent/tweet?url=${encodeURIComponent(
          this.url
        )}`;
        break;
      default:
        return;
    }
    window.open(shareUrl, '_blank');
  }

  public navigateToCampaignSummary(campaign: any) {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      company_id: campaign?.company_id,
      campaign_id: campaign.id,
    });
    this.router.navigate(['/corporate/campaigns/campaign-summary'], {
      queryParams: { data: encryptedParams },
    });
  }

  private navigateToCreateCampaign(campaign: any) {
    const encryptedParams = this.localStorageService.encryptObjectUrl({
      id: campaign.id,
      ngo_id: campaign?.ngo_id,
      project_id: campaign?.project_id,
      title: campaign?.title,
    });
    this.router.navigate(['/corporate/campaigns/create'], {
      queryParams: { data: encryptedParams },
    });
  }
}
