import { PaymentTypeEnum } from "../../enum/payment-type.enum";

export class CorporateEmployeeModel {
  company_id: string = null;
  company_name: string = null;
  user_id: string = null;
  name: string = null;
  email: string = null;
  about: string = null;
  office_id: string = null;
  department_id: string = null;
  department_name: number = null;
  designation: number = null;
  joining_date: Date = new Date();
  skills: string = null;
  //volunteer_hours: number = 0;
  causes: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}