<div class="main_div">
  <div style="margin-bottom: 32px">
    <span class="colored">Home </span> / Dashboard
  </div>
  <div class="main_body">
    <div class="main_body_title">Corporate Dashboard</div>

    <div class="overview_carts">
      <div class="carts">
        <div class="upper_part">
          <div class="left_side">
            <div class="carts_title">Funds Used</div>
            <div class="carts_description">
              Funds matched by the corporation.
            </div>
          </div>
          <div class="right_side">
            <div class="carts_img">
              <img
                loading="lazy"
                [src]="'./assets/images/icon/funds-used.svg'"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="total_amount">৳ {{ dashboard?.fund_used | number }}</div>
      </div>
      <div class="carts">
        <div class="upper_part">
          <div class="left_side">
            <div class="carts_title">Campaign Running</div>
            <div class="carts_description">
              Active campaigns currently underway.
            </div>
          </div>
          <div class="right_side">
            <div class="carts_img">
              <img
                loading="lazy"
                [src]="'./assets/images/icon/campaign-running.svg'"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="total_amount">
          {{ dashboard?.running_campaign | number }}
        </div>
      </div>
      <div class="carts">
        <div class="upper_part">
          <div class="left_side">
            <div class="carts_title">Total Campaigns</div>
            <div class="carts_description">All Campaigns Launched so far.</div>
          </div>
          <div class="right_side">
            <div class="carts_img">
              <img
                loading="lazy"
                [src]="'./assets/images/icon/total-campaigns.svg'"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="total_amount">
          {{ dashboard?.total_campaign | number }}
        </div>
      </div>
      <div class="carts">
        <div class="upper_part">
          <div class="left_side">
            <div class="carts_title">Participation Rate</div>
            <div class="carts_description">
              Presentence of employees participation.
            </div>
          </div>
          <div class="right_side">
            <div class="carts_img">
              <img
                loading="lazy"
                [src]="'./assets/images/icon/participation-rate.svg'"
                alt=""
              />
            </div>
          </div>
        </div>
        <div class="total_amount">
          {{ dashboard?.participation_rate | number }}%
        </div>
      </div>
    </div>

    <div class="main_body_title">Quick Links</div>

    <div class="quick_links">
      <div class="payment_cards">
        <div class="total_fund_raised quick">
          <div class="left">
            <div class="icon_quick">
              <img src="./assets/images/icon/approve-matching.svg" />
            </div>
            <div class="title_amount">
              <div class="amount quick_type">Launch a New Campaign</div>
            </div>
          </div>
          <div class="icon_expand">
            <img
              class="cursor"
              src="/assets/images/icon/pencil-edit-blue.svg"
              [routerLink]="['/corporate/campaigns/all-campaigns']"
            />
          </div>
        </div>
        <div class="total_fund_raised quick">
          <div class="left">
            <div class="icon_quick">
              <img
                src="./assets/images/icon/launch-new-campaign.svg"
                alt=""
                srcset=""
              />
            </div>
            <div class="title_amount">
              <div class="amount quick_type">Create Employee Account</div>
            </div>
          </div>
          <div class="icon_expand">
            <img
              class="cursor"
              src="/assets/images/icon/pencil-edit-blue.svg"
            />
          </div>
        </div>
      </div>
    </div>
    <div class="main_body_title_icon">
      <div class="main_body_title">Recent Activity</div>
      <div (click)="activityListPopup = true" class="see_all cursor">
        View All
      </div>
    </div>
    <div class="grid_component">
      <div class="top_donor_div pr-0">
        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="[]"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="false"
          >
            <dxi-column
              [width]="'20%'"
              caption="Date & Time"
              dataField="Date_Time"
            ></dxi-column>
            <dxi-column
              [width]="'20%'"
              caption="Activity  Type"
              dataField="activity_Type"
            >
            </dxi-column>
            <dxi-column
              [width]="'40%'"
              [minWidth]="'200px'"
              caption="Description"
              dataField="description"
            >
            </dxi-column>
            <dxi-column [width]="'20%'" caption="User" dataField="user">
            </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row">
                <td>
                  <div class="normal_data">
                    {{ row?.data?.Date_Time }}
                  </div>
                  <div class="normal_time">
                    {{ row?.data?.time }}
                  </div>
                </td>
                <td>
                  <div class="normal_data">
                    {{ row.data?.activity_Type }}
                  </div>
                </td>
                <td>
                  <div class="normal_data">
                    {{ row.data?.description }}
                  </div>
                </td>
                <td>
                  <div class="normal_data">
                    {{ row.data?.user }}
                  </div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>
    <div class="main_body_title_icon">
      <div class="main_body_title">Employee Recognition</div>
      <div (click)="employeeRecognitionPopup = true" class="see_all cursor">
        View All
      </div>
    </div>
    <div class="grid_component">
      <div class="top_donor_div pr-0">
        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="donorLeaderBoard"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="true"
          >
            <dxo-paging [pageSize]="3"></dxo-paging>
            <dxo-pager
              [visible]="false"
              [showPageSizeSelector]="false"
              allowedPageSizes="10"
              [showInfo]="true"
            >
            </dxo-pager>
            <dxi-column caption="Employee Name" dataField="name"></dxi-column>
            <dxi-column
              caption="Contributions"
              alignment="start"
              dataField="total_amount"
            >
            </dxi-column>
            <dxi-column caption="Campaigns Participate"> </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row row_">
                <td>
                  <!-- [routerLink]="'/corporate/employee-information'"
                  [queryParams]="{ id: row?.data.id }" -->
                  <div
                    class="combine cursor"
                  >
                    <div class="employee_rank">
                      <img
                        loading="lazy"
                        [src]="
                          './assets/images/icon/trophy_' +
                          row?.rowIndex +
                          '.svg'
                        "
                        alt="rank"
                      />
                    </div>
                    <div class="employee_profile">
                      <img
                        loading="lazy"
                        [src]="
                          row?.data?.profile_img
                            ? row?.data?.profile_img?.url
                            : './assets/images/round_profile.png'
                        "
                        alt="PROFILE"
                      />
                    </div>
                    <div class="normal_data" style="padding-top: 5px">
                      {{ row?.data?.name }}
                    </div>
                  </div>
                </td>
                <td>
                  <div class="normal_data">
                    ৳ {{ row.data?.total_amount | number }}
                  </div>
                </td>
                <td>
                  <div class="combine">
                    <div class="employee_rank multiple_icon">
                      <img
                        loading="lazy"
                        src="./assets/images/icon/person-logo-icon.svg"
                        alt="rank"
                      />
                    </div>
                    <span class="normal_data" style="color: #0054a6">
                      ({{ row.data?.campaign_ids?.length | number }})
                    </span>
                  </div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>

<dx-popup
  [width]="'90vw'"
  [height]="'80vh'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="employeeRecognitionPopup"
  [showTitle]="false"
>
  <div class="root">
    <div
      class="cross_button_div_"
      style="margin-right: 20px; text-align: right; width: 100%"
    >
      <button class="cross_button" (click)="employeeRecognitionPopup = false">
        <img
          src="./assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>
    <div class="popUpTitle">Employee Recognition</div>
    <div class="donor_div pb-5">
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="donorLeaderBoard"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="true"
      >
        <dxo-paging [pageSize]="10"></dxo-paging>
        <dxo-pager
          [visible]="true"
          [showPageSizeSelector]="false"
          allowedPageSizes="10"
          [showInfo]="true"
        >
        </dxo-pager>
        <dxi-column caption="Employee Name" dataField="name"></dxi-column>
        <dxi-column
          caption="Contributions"
          alignment="start"
          dataField="total_amount"
        >
        </dxi-column>
        <dxi-column caption="Campaigns Participate"> </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row row_">
            <td>
              <div
                class="combine cursor"
                [routerLink]="['/corporate/employee-information']"
                [queryParams]="{ id: row?.data.id }"
              >
                <div class="employee_rank">
                  <img
                    *ngIf="row?.rowIndex < 3"
                    loading="lazy"
                    [src]="
                      './assets/images/icon/trophy_' + row?.rowIndex + '.svg'
                    "
                    alt="rank"
                  />
                  <span *ngIf="row?.rowIndex > 2">{{ row?.rowIndex }}</span>
                </div>
                <div class="employee_profile">
                  <img
                    loading="lazy"
                    [src]="
                      row?.data?.profile_img
                        ? row?.data?.profile_img?.url
                        : './assets/images/round_profile.png'
                    "
                    alt="PROFILE"
                  />
                </div>
                <div class="normal_data" style="padding-top: 5px">
                  {{ row?.data?.name }}
                </div>
              </div>
            </td>
            <td>
              <div class="normal_data">
                ৳ {{ row.data?.total_amount | number }}
              </div>
            </td>
            <td>
              <div class="combine">
                <div class="employee_rank multiple_icon">
                  <img
                    loading="lazy"
                    src="./assets/images/icon/person-logo-icon.svg"
                    alt="rank"
                  />
                </div>
                <span class="normal_data" style="color: #0054a6">
                  ({{ row.data?.campaign_ids?.length | number }})
                </span>
              </div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>

<dx-popup
  [width]="'90vw'"
  [height]="'90vh'"
  [hideOnOutsideClick]="false"
  [showCloseButton]="true"
  [(visible)]="activityListPopup"
  [showTitle]="false"
>
  <div class="root">
    <div
      class="cross_button_div_"
      style="margin-right: 20px; text-align: right; width: 100%"
    >
      <button class="cross_button" (click)="activityListPopup = false">
        <img
          src="./assets/images/icon/x-circle.svg"
          alt="cross"
          loading="lazy"
          title="close"
        />
      </button>
    </div>
    <div style="margin-bottom: 36px" class="popUpTitle">Recent Activity</div>
    <div class="donor_div">
      <div class="date_picker">
        <div class="date_picker_title">By Date Range:</div>
        <dx-date-range-box
          class="time_line"
          [startDate]="startDate"
          [endDate]="endDate"
          labelMode="floating"
          [min]="oneYearAgo"
          [max]="today"
          displayFormat="dd MMM, yyyy"
        ></dx-date-range-box>
      </div>
      <dx-data-grid
        class="chart_div data_grid"
        [dataSource]="[]"
        [showBorders]="false"
        [showColumnLines]="false"
        [showRowLines]="false"
        [allowColumnResizing]="true"
        columnResizingMode="widget"
        [focusedRowEnabled]="false"
        [rowAlternationEnabled]="false"
        rowTemplate="rowTemplateName"
        [wordWrapEnabled]="true"
        [columnAutoWidth]="false"
      >
        <dxi-column
          [width]="'20%'"
          caption="Date & Time"
          dataField="Date_Time"
        ></dxi-column>
        <dxi-column
          [width]="'20%'"
          caption="Activity  Type"
          dataField="activity_Type"
        >
        </dxi-column>
        <dxi-column
          [width]="'40%'"
          [minWidth]="'200px'"
          caption="Description"
          dataField="description"
        >
        </dxi-column>
        <dxi-column [width]="'20%'" caption="User" dataField="user">
        </dxi-column>

        <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
          <tr class="custom-row">
            <td>
              <div class="normal_data">
                {{ row?.data?.Date_Time }}
              </div>
              <div class="normal_time">
                {{ row?.data?.time }}
              </div>
            </td>
            <td>
              <div class="normal_data">
                {{ row.data?.activity_Type }}
              </div>
            </td>
            <td>
              <div class="normal_data">
                {{ row.data?.description }}
              </div>
            </td>
            <td>
              <div class="normal_data">
                {{ row.data?.user }}
              </div>
            </td>
          </tr>
        </tbody>
      </dx-data-grid>
    </div>
  </div>
</dx-popup>
