import { CorporateProfileService } from 'src/app/shared/services/corporate/corporate-profile/corporate-profile.service';
import { UtilitiesService } from './../../../shared/services/utilities/utilities.service';
import { CorporateCampaignService } from './../../../shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { Component, OnInit } from '@angular/core';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';

@Component({
  selector: 'app-corporate-dashboard',
  templateUrl: './corporate-dashboard.component.html',
  styleUrl: './corporate-dashboard.component.scss',
})
export class CorporateDashboardComponent implements OnInit {
  public activityListPopup: boolean = false;
  public employeeRecognitionPopup: boolean = false;

  public today: Date = new Date();
  public oneYearAgo: Date = new Date(this.today.getFullYear() - 1);

  public startDate: Date = new Date();
  public endDate: Date = new Date(this.startDate.getFullYear() - 1);

  private companyId: string =
    this.localStorageService.getSessionUser().company_id;
  public dashboard: any;
  public donorLeaderBoard: any = [];

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly corporateProfileService: CorporateProfileService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {}

  ngOnInit(): void {
    if (this.companyId) {
      this.getDashboardData();
      this.getDonorLeaderBoard();
    }
  }

  private getDashboardData(): void {
    this.corporateProfileService
      .getCompanyDashboard(this.companyId)
      .subscribe((result: any) => {
        this.dashboard = result;
      });
  }

  private getDonorLeaderBoard(): void {
    this.corporateCampaignService
      .getCorporateDonorLeaderboard(this.companyId)
      .subscribe((result: any) => {
        this.donorLeaderBoard = result;
      });
  }
}
