import { Component } from '@angular/core';
import { NgxSpinnerService } from 'ngx-spinner';

@Component({
  selector: 'app-corporate-create-campaign-parent',
  templateUrl: './corporate-create-campaign-parent.component.html',
  styleUrl: './corporate-create-campaign-parent.component.scss',
})
export class CorporateCreateCampaignParentComponent {
  public showUI: boolean = true;

  constructor(private readonly spinner: NgxSpinnerService) {}

  public reload(e: any): void {
    this.spinner.show();
    this.showUI = false;
    setTimeout(() => {
      this.showUI = true;
      this.spinner.hide();
    }, 200);
  }
}
