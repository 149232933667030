import { PaymentTypeEnum } from "../../enum/payment-type.enum";

export class CorporateCampaignModel {
  company_id: string = null;
  company_name: string = null;
  title: string = null;
  subtitle: string = null;
  description: string = null;
  story_rich_text: string = null;
  cover_img_vdo: string = null;
  contact_person_name: string = null;
  matching_percentage: number = null;
  matching_limit: number = null;
  matching_budget: number = null;
  contact_person_job_title: string = null;
  contact_person_information: string = null;
  fundraising_target: number = null;
  start_date: Date = null;
  end_date: Date = null;
  is_fundraiser_private: boolean = true;
  payment_type: PaymentTypeEnum = PaymentTypeEnum.SHOHAY;
  emails: string[] = [];
  message: string = null;

  constructor(obj?: any) {
    if (obj) {
      Object.keys(this).forEach((key) => {
        if (obj.hasOwnProperty(key)) {
          this[key] = obj[key];
        }
      });
    }
  }
}