<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title"> Home </span>
    <span> > Campaigns</span>
  </div>

  <div class="header">
    <div class="header_text">
      <div class="donation_camp_text">Campaigns</div>
      <div class="start_new_text">
        Start a new campaign to raise funds for your cause.
      </div>
    </div>
    <div>
      <button
        class="blue-button-md create_button"
        [routerLink]="['/corporate/campaigns/create']"
      >
        <img
          class="add_icon"
          src="./assets/images/icon/add_circle_icon.svg"
          alt="add"
        />
        Create Campaign
      </button>
    </div>
  </div>

  <!-- panel-title -->
  <div class="full_panel row d-flex justify-content-between">
    <div class="col d-flex p-0">
      <dx-tab-panel
        (onSelectionChanged)="onTabChange($event)"
        style="box-shadow: none"
        [swipeEnabled]="true"
      >
        <dxi-item
          title="Running"
          [icon]="
            activeTab === 0
              ? '/assets/images/icon/schedule_icon.svg'
              : '/assets/images/icon/schedule_icon_active.svg'
          "
        >
        </dxi-item>
        <dxi-item
          title="Stopped"
          [icon]="
            activeTab === 1
              ? '/assets/images/icon/bookmark-active.svg'
              : '/assets/images/icon/bookmark-inactive.svg'
          "
        >
        </dxi-item>
      </dx-tab-panel>
    </div>

    <div class="col panel_right_side d-flex gap-2 justify-content-end p-0">
      <dx-text-box
        class="search_box"
        placeholder="Filter By keyword"
        [showClearButton]="true"
        (onInput)="onSearchKeywordChanged($event)"
        [buttons]="[
          {
            name: 'searchCampaign',
            location: 'before',
            options: {
              icon: 'find',
              elementAttr: {
                class: 'searchForm'
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
    </div>
  </div>
  <!-- panel-title-end -->

  <!-- tab-panel-content -->
  <div class="panel_content_for_my_campaign">
    <div class="campaign_list">
      <div *ngFor="let campaign of activeDataSource; let i = index">
        <div
          class="campaign_card cursor"
          (click)="navigateToCampaignSummary(campaign)"
        >
          <div class="title_div">
            <div class="cover_img_div">
              <img
                class="cover_image"
                [src]="campaign?.cover_img_vdo?.url"
                alt="campaign-cover-image"
                title="campaign-cover-image"
              />
            </div>

            <div class="title_subtitle flex-grow-1">
              <div class="subtitle">{{ campaign?.subtitle }}</div>
              <div class="title">{{ campaign?.title }}</div>
              <div class="status_child">
                <span class="donation_number"
                  >Matching Budget - ৳
                  {{ campaign?.matching_budget | number }}
                </span>
                <span class="donation_number">
                  Matching Percentage -
                  {{ campaign?.matching_percentage | number : "1.1-1" }}%
                </span>
              </div>
            </div>

            <div class="d-flex gap-4 flex-grow-1">
              <div class="status_div flex-grow-1">
                <div class="status_child">
                  <div class="status">
                    Status:
                    <div class="status_text">{{ campaign?.status }}</div>
                  </div>
                  <div class="img_number">
                    <div class="img">
                      <img
                        class="arrow-right-alt-icon"
                        loading="lazy"
                        alt=""
                        src="../../../../../assets/images/icon/user-multiple.svg"
                      />
                    </div>
                    <div class="number">
                      {{ campaign?.donation_count | number }}
                    </div>
                  </div>
                </div>
                <div>
                  <dx-progress-bar
                    class="progress"
                    [min]="0"
                    [max]="campaign?.fundraising_target"
                    [value]="campaign?.total_fund_raised"
                    [showStatus]="false"
                    [hint]="
                      (campaign?.total_fund_raised /
                        campaign?.fundraising_target) *
                        100 +
                      '%'
                    "
                  ></dx-progress-bar>
                </div>
                <div class="donation_count">
                  <span class="donation_number fw-bolder">
                    Total - ৳
                    {{ campaign?.total_fund_raised | number }}
                  </span>

                  <span class="donation_number">
                    Target - ৳ {{ campaign?.fundraising_target | number }}
                  </span>
                </div>
                <div class="donation_count">
                  <span class="donation_number">
                    Collected - ৳
                    {{ campaign?.total_fund_raised | number }}
                  </span>

                  <span class="donation_number">
                    Matched - ৳ {{ campaign?.fundraising_target | number }}
                  </span>
                </div>
              </div>
              <div class="date_div">
                <span class="form_date"
                  >Created on:
                  {{ campaign?.created_at | date : "dd MMM yyyy" }}</span
                >
                <div (click)="stopClickEvent($event)">
                  <dx-drop-down-button
                    style="font-size: 16px !important"
                    [dropDownOptions]="{ width: 262 }"
                    [showArrowIcon]="false"
                    [icon]="'./assets/images/icon/more_vertical_icon.svg'"
                    keyExpr="id"
                    displayExpr="text"
                    (onItemClick)="buttonClick($event, campaign)"
                    [items]="[
                            {
                              id:
                                campaign?.status === campaignStatusEnum.RUNNING
                                  ? 'end_campaign'
                                  : 'start_campaign',
                              text:
                                campaign?.status === campaignStatusEnum.RUNNING
                                  ? 'End Campaign'
                                  : 'Start Campaign',
                              icon:
                                campaign?.status === campaignStatusEnum.RUNNING
                                  ? './assets/images/icon/stop_icon.svg'
                                  : './assets/images/icon/start_icon.svg',
                            },
                            {
                              id: 'edit_campaign',
                              text: 'Edit',
                              icon: './assets/images/icon/editIcon.svg',
                              disabled: true
                            },
                            {
                              id: 'delete_campaign',
                              text: 'Delete',
                              icon: './assets/images/icon/delete.svg',
                            },
                            {
                              id: 'share_campaign',
                              text: 'Share',
                              icon: './assets/images/icon/share_icon.svg'
                            },
                  ]"
                  >
                  </dx-drop-down-button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- tab-panel-content-end -->

    <div
      *ngIf="allCampaign.length === 0"
      class="No_form_container d-flex flex-column align-items-center"
      style="margin-top: 50px"
    >
      <img
        src="./assets/images/icon/donation_colorful_icon.svg"
        alt="donation-image"
        title="donations"
      />
      <span class="no_form_workspace">No campaign in workspace yet</span>
      <span class="select_form_text"
        >Select a blank Campaign or choose another template to get started</span
      >
      <button
        class="blue-button-md form_button"
        [routerLink]="['/corporate/campaigns/create']"
      >
        Create Campaign
      </button>
    </div>
  </div>

  <dx-popup
    [(visible)]="sharePopUp"
    [width]="960"
    [height]="490"
    [showCloseButton]="false"
    [showTitle]="false"
    [dragEnabled]="false"
    [resizeEnabled]="false"
    position="center"
    [enableBodyScroll]="false"
    [hideOnOutsideClick]="true"
  >
    <div class="share_pop_up">
      <div class="support_text">Support by sharing with your network</div>
      <div class="social_share">Social share can be raise up 3X more</div>
      <div class="url_link_container">
        <!-- placeholder="Your f" -->
        <dx-text-box
          class="myTextBox"
          [showClearButton]="false"
          [readOnly]="true"
          [(value)]="url"
        >
          <dx-validator validationGroup="validationGrp"> </dx-validator>
        </dx-text-box>
        <div class="copy_text" (click)="copyText()">
          <img
            src="../../../../assets/images/icon/copy_text_blue_icon.svg"
            alt="facebook"
            title="facebook"
          />
          <div>Copy</div>
        </div>
      </div>
      <div class="social_media_div">
        <div class="social_media" (click)="shareOnSocialMedia('facebook')">
          <img
            src="../../../../assets/images/article-news/icon/2023_Facebook_icon 1.svg"
            alt="facebook"
            title="facebook"
          />
          <div>Facebook</div>
        </div>
        <div class="social_media" (click)="shareOnSocialMedia('linkedin')">
          <img
            src="./assets/images/logo/linkedin_logo.svg"
            alt="linked"
            title="linkedin"
          />
          <div>LinkedIn</div>
        </div>
        <div class="social_media" (click)="shareOnSocialMedia('whatsapp')">
          <img
            src="./assets/images/icon/whatsapp_icon.svg"
            alt="facebook"
            title="facebook"
          />
          <div>WhatsApp</div>
        </div>
        <div class="social_media" (click)="shareOnSocialMedia('twitter')">
          <img
            src="./assets/images/icon/x_twitter_icon.svg"
            alt="facebook"
            title="facebook"
          />
          <div>Twitter</div>
        </div>
      </div>
    </div>
  </dx-popup>
</div>
