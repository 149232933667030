import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { CampaignStatusEnum } from 'src/app/shared/enum/campaign-status.enum';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { CorporateProfileService } from 'src/app/shared/services/corporate/corporate-profile/corporate-profile.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { ChangeDetectorRef } from '@angular/core';

@Component({
  selector: 'app-corporate-campaign-dashboard',
  templateUrl: './corporate-campaign-dashboard.component.html',
  styleUrl: './corporate-campaign-dashboard.component.scss',
})
export class CorporateCampaignDashboardComponent {
  public campaignStatusEnum = CampaignStatusEnum;

  public activeTab: number = 1;

  public row1 = [
    {
      carts_icon: './assets/images/icon/multiple-user.svg',
      carts_name: 'Participating Employee',
      isAmount: false,
      calculated_data: '1200000',
    },
    {
      carts_icon: './assets/images/icon/donate_hand_icon.svg',
      carts_name: 'Total Collected',
      isAmount: true,
      calculated_data: '12',
    },
    {
      carts_icon: './assets/images/icon/cash-back.svg',
      carts_name: 'Matched Amount',
      carts_description: 'All Campaigns Launched so far ',
      isAmount: true,
      calculated_data: '45',
    },
  ];
  public row2 = [
    {
      carts_icon: './assets/images/icon/money-bag.svg',
      carts_name: 'Total Collection',
      carts_description: 'Funds matched by the corporation.',
      isAmount: true,
      calculated_data: '1200000',
    },
    {
      carts_icon: './assets/images/icon/money.svg',
      carts_name: 'Remaining Budget',
      carts_description: 'Active campaigns currently underway',
      isAmount: true,
      calculated_data: '12',
    },
    {
      carts_icon: './assets/images/icon/event.svg',
      carts_name: 'Days Left',
      carts_description: 'All Campaigns Launched so far ',
      isAmount: false,
      calculated_data: '45',
    },
  ];
  public row3 = [
    {
      carts_icon: './assets/images/icon/percentage.svg',
      carts_name: 'Matching Percentage',
      carts_description: 'Funds matched by the corporation.',
      isAmount: false,
      calculated_data: '1200000',
    },
    {
      carts_icon: './assets/images/icon/financial-analysis.svg',
      carts_name: 'Matching Limit per Employee',
      carts_description: 'Active campaigns currently underway',
      isAmount: true,
      calculated_data: '12',
    },
  ];

  public buttonText: string = null;
  public buttonImage: string =
    '../../../../assets/images/icon/gg_play-stop-r.svg';

  public selectedChart = 'bar-chart';
  public lineChartDays = '30 Days';

  public lineChartData = [
  ];

  public donations = [
  ];

  public totalDonationMoney = 0;

  public selectedPeriod = 'last15Days'; // Default selection

  public campaign: any;
  public donationSummary: any = null;
  public campaign_id: string = null;
  public companyId: string;
  public isDonationApiHasData: boolean = true;
  public today = new Date();

  constructor(
    private readonly corporateCampaignService: CorporateCampaignService,
    private readonly route: ActivatedRoute,
    private readonly localStorageService: LocalStorageService,
    private readonly router: Router,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        this.campaign_id = decryptedParams.campaign_id;
        if (this.campaign_id) {
          this.getCampaignSummaryDashboard();
          this.getCampaignAnalytics(this.setDate(15));
          this.getEmployeeParticipating(this.setDate(15));
        }
      } else {
        this.router.navigateByUrl('/corporate/campaigns/all-campaigns');
        console.error('Decryption failed');
      }
    });
  }

  public getCampaignSummaryDashboard() {
    this.corporateCampaignService
      .getCampaignSummaryDashboard(this.campaign_id)
      .subscribe({
        next: (result) => {
          this.row1[0].calculated_data = result.participating_employee;
          this.row1[1].calculated_data = result.total_collected;
          this.row1[2].calculated_data = result.matched_amount;
          this.row2[0].calculated_data = result.total_collection;
          this.row2[1].calculated_data = result.remaining_budget;
          this.row2[2].calculated_data = result.days_left;
          this.row3[0].calculated_data = result.matching_percentage;
          this.row3[1].calculated_data = result.matching_limit_per_employee;
          this.donationSummary = result.recent_donations.map(donation => ({
            amount: donation.amount,
            name: donation.name,
            time: this.getMinutesAgo(donation.created_at),
          }));
          console.log(this.donationSummary);
          if(result.days_left > 0){
            this.buttonText = 'STOP'
          }else{
            this.buttonText = 'START';
          }
          // this.donatedCampaigns =
        },
      });
  }

  public getCampaignAnalytics(date: string) {
    this.corporateCampaignService
      .getDonationAnalytics(this.campaign_id, date)
      .subscribe({
        next: (result) => {
          this.donations = [...this.formatDates(result)];
          this.cdr.detectChanges();
        },
      });
  }

  public getEmployeeParticipating(date: string) {
    this.corporateCampaignService
      .getEmployeeParticipating(this.campaign_id, date)
      .subscribe({
        next: (result) => {
          this.lineChartData = [...this.formatDates(result)];
          this.cdr.detectChanges();
          // this.lineChartData = this.formatDates(this.lineChartData);
        },
      });
  }

  get getTotalAmount(): number {
    return this.donations?.reduce((sum, item) => sum + (item.total_amount || 0), 0) || 0;
  }

  get getTotalEmployees(): number {
    return this.lineChartData?.reduce((sum, item) => sum + (item.total_employee || 0), 0) || 0;
  }

  private getMinutesAgo(timestamp: string): string {
    const createdTime = new Date(timestamp);
    const currentTime = new Date();
    const diffMs = currentTime.getTime() - createdTime.getTime();
    const diffMinutes = Math.floor(diffMs / 60000); // Convert milliseconds to minutes
    return `${diffMinutes} minutes ago`;
  };

  
  public async toggleButton() {
    if (this.buttonText === 'START') {
      await this.startCampaign(this.campaign_id);
    } else if (this.buttonText === 'STOP') {
      this.stopCampaign(this.campaign_id);
    }
  }

  public onChartChange(e: any) {
    this.selectedChart = e.value;
  }

  public onTimePeriodChange(event: any): void {
    this.selectedPeriod = event.value;
    if (this.selectedPeriod === 'last15Days') {
      this.getCampaignAnalytics(this.setDate(15));
      this.getEmployeeParticipating(this.setDate(15));
      this.lineChartDays = '15 Days';
    } else if (this.selectedPeriod === 'lastWeek') {
      this.getCampaignAnalytics(this.setDate(7));
      this.getEmployeeParticipating(this.setDate(7));
      this.lineChartDays = '7 Days'
    } else {
      this.getCampaignAnalytics(this.setDate(30));
      this.getEmployeeParticipating(this.setDate(30));
      this.lineChartDays = '30 Days'
    }
  }

  public setDate(daysBefore: number): string {
    const date = new Date();
    date.setDate(date.getDate() - daysBefore);
    return date.toISOString();
  }

  private formatDates(donations: any[]): any[] {
    return donations.map((donation) => {
      return {
        ...donation,
        formattedDate: this.convertDate(donation.day),
      };
    });
  }

  private convertDate(dateString: string): string {
    const date = new Date(dateString);
    const options: Intl.DateTimeFormatOptions = {
      month: 'short',
      day: 'numeric',
    };
    return date.toLocaleDateString('en-US', options);
  }

  private async startCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to start the campaign?',
      'Yes, Start'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();
      this.corporateCampaignService
        .startCorporateCampaign(campaignId, currentDate)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign started successfully!'
            );
            this.buttonText = 'STOP';
            this.buttonImage =
              '../../../../assets/images/icon/gg_play-stop-r.svg';
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error starting the campaign'
            );
          },
        });
    }
  }

  private async stopCampaign(campaignId: string) {
    const response = await this.confirmAction(
      'You want to stop the campaign?',
      'Yes, Stop'
    );
    if (response.isConfirmed) {
      const currentDate = new Date().toISOString();
      this.spinner.show();
      this.corporateCampaignService
        .stopCorporateCampaign(campaignId, currentDate)
        .subscribe({
          next: (res) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'success',
              'Campaign stopped successfully!'
            );
            this.buttonText = 'START';
            this.buttonImage =
              '../../../../assets/images/icon/pause_circle.svg';
          },
          error: (err) => {
            this.spinner.hide();
            this.utilitiesService.showSwal(
              'error',
              'Error stopped the campaign'
            );
          },
        });
    }
  }

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }
}
