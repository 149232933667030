import { Component, OnInit } from '@angular/core';
import { CorporateProfileModel } from 'src/app/shared/model/corporate/corporate-profile.model';
import { CorporateProfileService } from 'src/app/shared/services/corporate/corporate-profile/corporate-profile.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import ValidationEngine from 'devextreme/ui/validation_engine';

@Component({
  selector: 'app-corporate-company-profile',
  templateUrl: './corporate-company-profile.component.html',
  styleUrl: './corporate-company-profile.component.scss',
})
export class CorporateCompanyProfileComponent implements OnInit {
  public progress = 25;
  public activeTabIndex = 0;
  public editMode = false;

  private sessionUser: any = this.localStorageService.getSessionUser();
  private companyId: string;
  public profile: any;
  public updateBody: CorporateProfileModel = new CorporateProfileModel();

  constructor(
    private readonly localStorageService: LocalStorageService,
    private readonly corporateProfileService: CorporateProfileService
  ) {}

  ngOnInit(): void {
    this.getProfile();
  }

  private getProfile(): void {
    this.corporateProfileService
      .getCompanyProfile(this.sessionUser?.id)
      .subscribe((result: any) => {
        this.companyId = result.id;
        this.profile = result;
        this.updateBody = new CorporateProfileModel(this.profile);
      });
  }

  public updateProfile(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.corporateProfileService
        .updateCorporateProfile(this.companyId, this.updateBody)
        .subscribe((result: any) => {
          this.editMode = !this.editMode;
        });
    }
  }

  connectedList: any = [
    {
      img: 'assets/images/icon/usaid_icon.svg',
    },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
    // {
    //   img : 'assets/images/icon/usaid_icon.svg',
    // },
  ];

  public onTabChange(event: any) {
    this.activeTabIndex = event.component.option('selectedIndex');
  }

  public toggleEditMode() {
    this.editMode = !this.editMode;
  }
}
