import { Component } from '@angular/core';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { EmployeeService } from 'src/app/shared/services/corporate/employee/employee.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';

@Component({
  selector: 'app-employee-engagement',
  templateUrl: './employee-engagement.component.html',
  styleUrl: './employee-engagement.component.scss',
})
export class EmployeeEngagementComponent {
  public overviewCarts = [
    {
      carts_icon: './assets/images/icon/funds-used.svg',
      carts_name: 'Participating Employee',
      carts_description: 'Total Participating Employee',
      isAmount: false,
      isRate: false,
      calculated_data: '0',
    },
    {
      carts_icon: './assets/images/icon/campaign-running.svg',
      carts_name: ' Participation Rate',
      carts_description: 'Presentence of employees participation.',
      isAmount: false,
      isRate: true,
      calculated_data: '0',
    },
    {
      carts_icon: './assets/images/icon/total-campaigns.svg',
      carts_name: 'Total Contributions',
      carts_description: 'Funds matched by the corporation.',
      isAmount: true,
      isRate: false,
      calculated_data: '0',
    },
    {
      carts_icon: './assets/images/icon/participation-rate.svg',
      carts_name: ' Average Contributions',
      carts_description: 'Presentence of employees participation.',
      isAmount: true,
      isRate: false,
      calculated_data: 0,
    },
  ];

  public leaderBoard = [];
  private companyId: string = null;
  
  constructor(
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly employeeService: EmployeeService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {}

  ngOnInit(): void {
    this.companyId = this.localStorageService.getSessionUser().company_id;
    this.employeeEngagement();
    this.getDonorLeaderBoard();
  }

  public employeeEngagement() {
    this.employeeService.getEmployeeEngagementSummary().subscribe({
      next: (result) => {
        this.overviewCarts[0].calculated_data = result.participating_employee;
        this.overviewCarts[1].calculated_data = result.participation_rate;
        this.overviewCarts[2].calculated_data = result.total_contributions;
        this.overviewCarts[3].calculated_data = result.average_contributions;
      },
    });
  }
  
  private getDonorLeaderBoard(): void {
    this.corporateCampaignService
      .getCorporateDonorLeaderboard(this.companyId)
      .subscribe((result: any) => {
        this.leaderBoard = result;
        this.leaderBoard.map((item: any) => {
          item.profile_img = this.utilitiesService.jsonParse(item.profile_img);
        });
      });
  }
}
