import {
  Component,
  EventEmitter,
  OnInit,
  Output,
} from '@angular/core';
import { SafeHtml } from '@angular/platform-browser';
import { ActivatedRoute, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import {
  BankAccountModel,
} from 'src/app/shared/model/ngo-campaign.model';
import { FileService } from 'src/app/shared/services/file/file.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { RichTextService } from 'src/app/shared/services/rich-text/rich-text.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { CorporateCampaignModel } from 'src/app/shared/model/corporate/corporate-campaign.model';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';

@Component({
  selector: 'app-corporate-create-campaign',
  templateUrl: './corporate-create-campaign.component.html',
  styleUrl: './corporate-create-campaign.component.scss',
})
export class CorporateCreateCampaignComponent implements OnInit {
  public expandButtonOption: Record<string, unknown>;
  @Output() reload = new EventEmitter<void>();

  public showPopUp = false;
  public fullScreenPopup = false;

  public activeForm: number = 1;

  public selectDate: string;
  public hasFundraisingTeam: boolean = true;
  public fundraiserInviteType: string;
  public campaignModel: CorporateCampaignModel = new CorporateCampaignModel();
  public safeDescription: SafeHtml;

  public emailsList: any[] = [];
  public fundraiserEmails: any[];

  public coverImg: any = {
    //For showing the image
    name: null,
    url: null,
  };
  private coverImgFile: File; //For saving the image file

  public isOpenAddMethodPopup: boolean = false;
  public editBankAccount: boolean = false;
  private accountIndex: number;

  public sessionUser: any = this.localStorageService.getSessionUser();

  public campaign_details: any;
  public edit_mode: boolean = false;
  public has_donation: boolean = false;

  public bankAccount: BankAccountModel = new BankAccountModel();
  public selectedBankAccount: BankAccountModel;
  public bankAccounts: BankAccountModel[] = [
    {
      bank_name: 'IFIC Bank',
      account_holder_name: 'Person 1',
      account_no: 12345,
      routing_no: 234,
    },
    {
      bank_name: 'City Bank',
      account_holder_name: 'Person 2',
      account_no: 54321,
      routing_no: 432,
    },
  ];

  constructor(
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly fileService: FileService,
    private readonly richTextService: RichTextService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateCampaignService: CorporateCampaignService
  ) {
    this.expandButtonOption = {
      icon: 'expandform',
      onClick: () => {
        this.fullScreenPopup = true;
        this.showPopUp = false;
      },
    };

    this.route.queryParams.subscribe((params) => {
      const encryptedString = params['data'];
      const decryptedParams =
        this.localStorageService.decryptObjectUrl(encryptedString);
      if (decryptedParams) {
        // this.campaign_id = decryptedParams.id;
        // if (this.campaign_id) {
        //   this.edit_mode = true;
        //   this.getCampaignDonationById(
        //     this.campaign_id
        //   );
        // }
      }
    });
  }

  ngOnInit() {
    this.campaignModel.company_id = this.sessionUser?.company_id;
    this.campaignModel.company_name = this.sessionUser?.company_name;
  }

  public nextForm(flag: number): void {
    if (flag > 0) {
      const { isValid } = ValidationEngine.validateGroup('validationGrp');
      if (isValid) {
        this.activeForm += flag;
      }
    } else if (flag < 0 && this.activeForm >= 2) {
      this.activeForm += flag;
    }
  }

  public onFileChange(e: any): void {
    const input = e.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.coverImgFile = input.files[0];
      this.coverImg.name = this.coverImgFile?.name;
      this.coverImg.url = URL.createObjectURL(this.coverImgFile);
    }
  }

  public removeCoverImgVdo(): void {
    this.coverImg = {
      name: null,
      url: null,
    };
  }

  public selectDateChange(e: any): void {
    const currentDate = new Date();
    const endDate = new Date();

    switch (e.value) {
      case 'Rolling':
        this.campaignModel.start_date = currentDate;
        this.campaignModel.end_date = null;
        break;
      case 'Custom':
        this.campaignModel.start_date = null;
        this.campaignModel.end_date = null;
        break;
      default:
        endDate.setMonth(endDate.getMonth() + parseInt(e.value, 10));
        this.campaignModel.start_date = currentDate;
        this.campaignModel.end_date = endDate;
        break;
    }
  }

  public addEmail(e: any): void {
    if (UtilitiesService.isValidEmail(e?.text)) {
      this.emailsList.push(e?.text);
      this.emailsList = this.emailsList.sort((a, b) => a.localeCompare(b));
    }
  }

  public openAddMethodPopup(flag: boolean): void {
    const copyBankAccounts = JSON.parse(JSON.stringify(this.bankAccounts));
    ValidationEngine.resetGroup('accountValidationGrp');

    if (!flag && this.editBankAccount) {
      this.editBankAccount = false;
    }
    this.bankAccounts = copyBankAccounts;
    this.isOpenAddMethodPopup = flag;
  }

  public addBankAccount(): void {
    const { isValid } = ValidationEngine.validateGroup('accountValidationGrp');
    if (isValid) {
      this.bankAccounts.push(this.bankAccount);
      this.openAddMethodPopup(false);
    }
  }

  public editAccount(index: number): void {
    this.editBankAccount = true;
    this.accountIndex = index;
    this.bankAccount = this.bankAccounts[index];
    this.isOpenAddMethodPopup = true;
  }

  public saveEditedAccount(): void {
    const { isValid } = ValidationEngine.validateGroup('accountValidationGrp');
    if (isValid) {
      this.bankAccounts[this.accountIndex] = this.bankAccount;
      this.openAddMethodPopup(false);
    }
  }

  public deleteAccount(index: number): void {
    this.bankAccounts.splice(index, 1);
  }

  public async submit() {
    try {
      const uploadedCoverImgVdo = await lastValueFrom(
        this.fileService.uploadSingleFile(this.coverImgFile)
      );
      this.campaignModel.cover_img_vdo = JSON.stringify(uploadedCoverImgVdo[0]);
      const processedRichTextStory =
        await this.richTextService.processHtmlString(
          this.campaignModel.story_rich_text
        );
      this.campaignModel.story_rich_text = processedRichTextStory;

      this.createCampaign();
    } catch (error) {
      this.showHidePopup(false);
      this.utilitiesService.showSwal(
        'error',
        error.error.error_name,
        error.error.message
      );
    }
  }

  private createCampaign(): void {
    this.corporateCampaignService
      .createCorporateCampaign(this.campaignModel)
      .subscribe({
        next: (res) => {
          this.showHidePopup(false);
          this.onSuccess();
        },
        error: (err) => {
          this.showHidePopup(false);
          this.utilitiesService.showSwal(
            'error',
            'Error creating new campaign.'
          );
        },
      });
  }

  private async onSuccess() {
    const response = await this.utilitiesService.showSwalAndReturn({
      type: 'success',
      title: `Campaign has been created successfully.`,
      confirmButtonText: 'Explore Campaign',
      showCancelButton: true,
      cancelButtonText: 'Create New Campaign',
    });

    if (response.isConfirmed) {
      this.router.navigate(['/corporate/campaigns/all-campaigns']);
    } else {
      this.reload.emit();
    }
  }

  public showHidePopup(flag: boolean): void {
    this.showPopUp = flag;
    this.fullScreenPopup = false;
  }

  public triggerFileInput(inputId: string): void {
    const input = document.getElementById(inputId) as HTMLInputElement;
    if (input) {
      input.click();
    }
  }

  //drag-and-drop-files
  public onDropSuccess(event: DragEvent): void {
    event.preventDefault();
    const files = event.dataTransfer?.files;
    if (files.length > 0) {
      this.onFileChange({ event: { target: { files: files } } });
    }
  }

  public onDragOver(event: DragEvent) {
    event.preventDefault();
    const items = event.dataTransfer?.items;
    if (items) {
      event.dataTransfer.dropEffect = 'copy';
    }
  }

  public async cancelFunctionality() {
    const response = await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: `Are you sure?`,
      message: this.edit_mode
        ? `Do you want to cancel campaign update?`
        : `Do you want to cancel campaign creation?`,
      confirmButtonText: 'Yes',
      showCancelButton: true,
      cancelButtonText: 'No',
    });

    if (response.isConfirmed) {
      this.router.navigateByUrl('corporate/campaigns/all-campaigns');
    }
  }

  private getCampaignDonationById(
    ngo_id: string,
    project_id: string,
    campaign_id: string
  ) {
    // this.donationService
    //   .getDonationCampaignById(ngo_id, project_id, campaign_id)
    //   .subscribe({
    //     next: (result) => {
    //       if (result.total_fund_raised > 0) {
    //         this.has_donation = true;
    //       }
    //       this.campaign_details = result;
    //       this.campaignModel = new NgoCampaignModel(result);
    //       // this.campaignModel.title = this.campaign_details.title;
    //       // this.campaignModel.subtitle = this.campaign_details.subtitle;
    //       this.campaignModel.story_rich_text = this.richTextService.decodeHtml(
    //         this.campaign_details.story_rich_text
    //       );
    //       this.campaignModel.description = this.campaign_details.description;
    //       this.coverImg.name = this.utilitiesService.jsonParse(
    //         result.cover_img_vdo
    //       )?.originalname;
    //       this.coverImg.url = this.utilitiesService.jsonParse(
    //         result.cover_img_vdo
    //       )?.url;
    //     },
    //   });
  }

  public updateCampaign() {
    //const body = new NgoCampaignUpdateModel(this.campaignModel);
    // this.donationService
    //   .updateDonationCampaign(body, this.campaign_id)
    //   .subscribe({
    //     next: (result) => {
    //       this.utilitiesService.showSwalWithToast(
    //         'success',
    //         `Donation Campaign has been updated Successfully!`,
    //         'success'
    //       );
    //       this.router.navigateByUrl('dashboard/manage/campaign');
    //     },
    //   });
  }
}
