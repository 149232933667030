import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { EmployeeService } from 'src/app/shared/services/corporate/employee/employee.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';

@Component({
  selector: 'app-corporate-donor-information',
  templateUrl: './corporate-donor-information.component.html',
  styleUrl: './corporate-donor-information.component.scss',
})
export class CorporateDonorInformationComponent {
  public inviteDonatePopup: boolean = false;

  public donorsList: any = [];

  public basicData: any = [];

  public id: string = null;

  constructor(
    private readonly router: Router,
    private readonly sharedService: CommonSharedServiceService,
    private readonly employeeService: EmployeeService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateCampaignService: CorporateCampaignService,
    private route: ActivatedRoute
  ) {
    this.sharedService.updateReceiptPopupState(false);
  }

  ngOnInit() {
    this.route.queryParams.subscribe((params) => {
      this.id = params['id'];
      this.getEmployeeProfile(this.id);
      console.log('ID from query params:', this.id);
    });
  }

  public getEmployeeProfile(id: string) {
    this.employeeService.getEmployeeProfileWithId(id).subscribe({
      next: (result) => {
        this.donorsList = result.recent_donations.map((donor) => ({
          Donor_Name: donor.name,
          Mobile_No: donor.whatsapp_no || 'N/A', // Assuming WhatsApp number as mobile
          transfer_type: 'Unknown', // No transfer type provided in the data
          transaction_id: donor.transaction_id || 'N/A',
          Date: new Date(donor.created_at).toLocaleDateString('en-US', {
            year: 'numeric',
            month: 'long',
            day: 'numeric',
          }),
          Time: new Date(donor.created_at).toLocaleTimeString('en-US', {
            hour: '2-digit',
            minute: '2-digit',
            hour12: true,
          }),
          Amount: donor.amount,
          campaign: donor.campaign_title,
          status: donor.status,
          comments: donor.comment || 'No comments',
        }));
        this.basicData = result;
      },
    });
  }

  public closeCreateEmailPopup() {
    this.inviteDonatePopup = false;
  }

  public inviteDonatePopUpOpen() {
    this.inviteDonatePopup = true;
  }
}
