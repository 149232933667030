[
  {
    "index": 1,
    "id": null,
    "href": null,
    "routerLink": "/employee/employee-dashboard",
    "name": "Dashboard",
    "activeIcon": "./assets/images/icon/dashboard_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/dashboard_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 2,
    "id": null,
    "href": null,
    "routerLink": "/employee/campaigns/dashboard",
    "name": "Campaigns",
    "activeIcon": "./assets/images/icon/mike_tilted_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/mike_tilted_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 3,
    "id": null,
    "href": null,
    "routerLink": "/employee/employee-leaderboard",
    "name": "Leaderboard",
    "activeIcon": "./assets/images/icon/trophy_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/trophy_icon_inactive.svg",
    "submenu": []
  },
  {
    "index": 4,
    "id": null,
    "href": null,
    "routerLink": "/employee/my-profile",
    "name": "Account Settings",
    "activeIcon": "./assets/images/icon/settings_icon_active.svg",
    "inactiveIcon": "./assets/images/icon/settings_icon_inactive.svg",
    "submenu": []
  }
]
