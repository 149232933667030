import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CorporateCampaignService } from 'src/app/shared/services/corporate/corporate-campaign/corporate-campaign.service';
import { EmployeeService } from 'src/app/shared/services/corporate/employee/employee.service';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { CommonSharedServiceService } from 'src/app/shared/shared-services/common-shared-service/common-shared-service.service';

@Component({
  selector: 'app-corporate-employee-management',
  templateUrl: './corporate-employee-management.component.html',
  styleUrl: './corporate-employee-management.component.scss',
})
export class CorporateEmployeeManagementComponent {
  public allFundraisers = [
    // {
    //   slNo: 1,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 2,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 3,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 4,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 5,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 6,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 7,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 8,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 9,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 10,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
    // {
    //   slNo: 11,
    //   donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
    //   donorName: 'Maksudur Rahman',
    //   phoneNumber: '01786062801',
    //   transactionId: '71NXMA9X',
    //   paymentMethod: 'Bank Transfer',
    //   updated_at: '2025-01-02T18:56:22.193Z',
    //   amount: 10000,
    //   campaign: 'Student Welfare Relief Fund - 2024',
    //   status: 'Pledged',
    // },
  ];

  public showCredentials: boolean = false;
  public credentials: any;

  constructor(
      private readonly utilitiesService: UtilitiesService,
      private readonly localStorageService: LocalStorageService,
      private readonly employeeService: EmployeeService,
      private readonly corporateCampaignService: CorporateCampaignService
    ) {}
  
    ngOnInit(): void {
      this.getEmployeeOfCompany();
    }

    public getEmployeeOfCompany() {
      this.employeeService.getEmployeeOfCompany().subscribe({
        next: (result) => {
          this.allFundraisers = result.map((item, index) => ({
            slNo: index + 1,
            ...item
          }));
        },
      });
    }
}
