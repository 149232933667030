import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { NotFoundComponent } from 'src/app/shared/components/not-found/not-found.component';
import { CorporateCampaignListComponent } from './corporate-campaign/corporate-campaign-list/corporate-campaign-list.component';
import { CorporateEmployeeManagementComponent } from './corporate-employee-management/corporate-employee-management.component';
import { CorporateInviteEmployeeComponent } from './corporate-invite-employee/corporate-invite-employee.component';
import { CorporateCreateEmployeeAccountComponent } from './corporate-create-employee-account/corporate-create-employee-account.component';
import { CorporateDashboardComponent } from './corporate-dashboard/corporate-dashboard.component';
import { CorporateCampaignDashboardComponent } from './corporate-campaign/corporate-campaign-dashboard/corporate-campaign-dashboard.component';
import { CorporateCompanyProfileComponent } from './corporate-company-profile/corporate-company-profile.component';
import { EmployeeEngagementComponent } from './employee-engagement/employee-engagement.component';
import { CorporateCreateCampaignParentComponent } from './corporate-campaign/corporate-create-campaign-parent/corporate-create-campaign-parent.component';
import { CorporateDonorInformationComponent } from './coporate-donor-information/corporate-donor-information.component';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'corporate-dashboard',
    pathMatch: 'full',
  },
  {
    path: 'corporate-dashboard',
    component: CorporateDashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-information',
    component: CorporateDonorInformationComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaigns/all-campaigns',
    component: CorporateCampaignListComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaigns/create',
    component: CorporateCreateCampaignParentComponent,
    pathMatch: 'full',
  },
  {
    path: 'campaigns/campaign-summary',
    component: CorporateCampaignDashboardComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-engagement',
    component: EmployeeEngagementComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-management',
    component: CorporateEmployeeManagementComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-management/create-account',
    component: CorporateCreateEmployeeAccountComponent,
    pathMatch: 'full',
  },
  {
    path: 'employee-management/invite-employee',
    component: CorporateInviteEmployeeComponent,
    pathMatch: 'full',
  },
  {
    path: 'my-profile',
    component: CorporateCompanyProfileComponent,
    pathMatch: 'full',
  },
  {
    path: '**',
    component: NotFoundComponent,
  },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class CorporateRoutingModule {}
