<div class="main_div">
  <div class="breadcrumb">
    <span class="donation_title">Home / Employee Engagement </span>
    <span> / Employee Profile </span>
  </div>

  <div class="title_main_body_" style="margin-bottom: 32px">Donor Info</div>
  <div class="main_body">
    <div class="contact_profile">
      <div class="title_main_body">Contact Profile</div>
      <div class="upper_part">
        <div class="profile-avatar">
          <img
            class="profile_img"
            loading="lazy"
            src="./assets/images/icon/profile_icon_gray.png"
            alt="profile_pic"
          />
        </div>
        <div class="donor_name" style="margin-bottom: 8px; margin-top: 8px">
          {{ basicData.name }}
        </div>
        <div class="donor_name donation_time" style="margin-bottom: 8px">
          ৳ {{ basicData.total_donations }}
        </div>
        <div class="donations" style="width: 100%; text-align: start">
          <span class="donation_time">Last donation</span>:
          {{ basicData.last_donation_day | date : "MMM d, y" }}
        </div>
        <div class="donations" style="width: 100%; text-align: start">
          <span class="donation_time">First donation</span>:
          {{ basicData.first_donation_day | date : "MMM d, y" }}
        </div>
        <!-- <div class="donations bottom" style="width: 100%; text-align: start">
          <span class="donation_time">Donation Frequency</span>: 10 - 11
        </div> -->
        <div class="email">{{ basicData.email }}</div>
      </div>
      <div class="buttons_invite_email">
        <dx-button class="blue-button-md" [disabled]="true"
          >Send Email</dx-button
        >
        <dx-button
          [routerLink]="'/corporate/employee-management/invite-employee'"
          class="yellow-button-md"
          >Invite to Donate</dx-button
        >
      </div>
      <div class="lower_part">
        <dx-drop-down-button
          class="drop_down_button"
          width="100%"
          [dropDownOptions]="{ width: '100%' }"
          [showArrowIcon]="true"
          text="Donors Contact Details"
          displayExpr="text"
          [items]="[
            {
              text: 'Donors Contact Details',
              icon: './assets/images/icon/whatsapp_icon.svg'
            }
          ]"
        ></dx-drop-down-button>
        <dx-drop-down-button
          class="drop_down_button"
          width="100%"
          [dropDownOptions]="{ width: '100%' }"
          [showArrowIcon]="true"
          text="Fundraising Team"
          displayExpr="text"
          [items]="[
            {
              text: 'Donors Contact Details',
              icon: './assets/images/icon/whatsapp_icon.svg'
            }
          ]"
        ></dx-drop-down-button>
        <dx-drop-down-button
          class="drop_down_button"
          width="100%"
          [dropDownOptions]="{ width: '100%' }"
          [showArrowIcon]="true"
          text="Donation Payment Method"
          displayExpr="text"
          [items]="[
            {
              text: 'Bkash',
              icon: './assets/images/icon/whatsapp_icon.svg'
            }
          ]"
        ></dx-drop-down-button>
      </div>
    </div>
    <div class="recent_donations">
      <div class="title_main_body">Recent Donations</div>
      <div class="top_donor_div pr-0">
        <div class="donor_div">
          <dx-data-grid
            class="chart_div data_grid"
            [dataSource]="donorsList"
            [showBorders]="false"
            [showColumnLines]="false"
            [showRowLines]="false"
            [allowColumnResizing]="true"
            columnResizingMode="widget"
            [focusedRowEnabled]="false"
            [rowAlternationEnabled]="false"
            rowTemplate="rowTemplateName"
            [wordWrapEnabled]="true"
            [columnAutoWidth]="true"
          >
            <dxo-paging [pageSize]="7"></dxo-paging>
            <dxo-pager
              [visible]="true"
              [showPageSizeSelector]="false"
              allowedPageSizes="10"
              [showInfo]="true"
            >
            </dxo-pager>
            <dxi-column
              [width]="'20%'"
              caption="Date & Time"
              dataField="Donor_Name"
            ></dxi-column>
            <dxi-column
              [width]="'20%'"
              caption="Transaction Id"
              dataField="transaction_id"
            >
            </dxi-column>
            <dxi-column
              [width]="'25%'"
              caption="Campaign"
              dataField="campaign"
              alignment="start"
            >
            </dxi-column>
            <dxi-column
              [width]="'25%'"
              caption="Comments"
              dataField="Amount"
              alignment="start"
            >
            </dxi-column>
            <dxi-column
              width="'10%'"
              caption="Amounts"
              dataField="status"
              alignment="start"
            >
            </dxi-column>

            <tbody class="dx-row" *dxTemplate="let row of 'rowTemplateName'">
              <tr class="custom-row">
                <td>
                  <div class="transfer">
                    {{ row?.data?.Date }}
                  </div>
                  <div class="transfer_time">
                    {{ row?.data?.Time }}
                  </div>
                </td>
                <td class="campaign_name">
                  <div class="transfer">
                    {{ row.data?.transfer_type }}
                  </div>
                  <div class="transfer blue_underline">
                    {{ row?.data?.transaction_id }}
                  </div>
                </td>
                <td>
                  <div class="campaign_div blue">
                    {{ row?.data?.campaign }}
                  </div>
                </td>
                <td>
                  <div class="left_side_property" style="margin: 12px">
                    <div class="name_amount">
                      <div class="name">{{ row?.data?.comments }}</div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="amount_div">৳ {{ row?.data?.Amount }}</div>
                  <div
                    [ngClass]="
                      row?.data?.status === 'Donated'
                        ? 'campaign_div green'
                        : 'campaign_div red'
                    "
                  >
                    <li>
                      {{ row?.data?.status }}
                    </li>
                  </div>
                </td>
              </tr>
            </tbody>
          </dx-data-grid>
        </div>
      </div>
    </div>
  </div>
</div>
