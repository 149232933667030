<div class="main_div">
  <div style="margin-bottom: 32px">
    <span class="colored">Home / Campaigns </span> / Empower Education:
    Corporate Giving Initiative
  </div>
  <header class="counter">
    <div class="more-options">
      <div class="survey-status">
        <b class="employee-satisfaction-survey"
          >Empower Education: Corporate Giving Initiative 2025</b
        >
        <div class="active-status">
          <div class="active-status-wrapper">
            <a class="active_">Active</a>
          </div>
        </div>
      </div>
    </div>

    <div class="download-button-wrapper-parent">
      <dx-button
        class="toggle-button"
        (click)="toggleButton()"
        stylingMode="contained"
        type="normal"
      >
        <img class="social-img" [src]="buttonImage" />
        {{ buttonText }}
      </dx-button>
      <div class="more-vertical">
        <img
          class="more-vertical-icon"
          loading="lazy"
          alt=""
          src="./assets/images/icon/more_vertical_icon.svg"
        />
      </div>
    </div>
  </header>
  <div class="main_body">
    <div class="tab_div">
      <div class="main_body_title">Responses</div>
      <div class="tab_div w-auto">
        <div
          class="tab"
          [class.active]="activeTab === 1"
          (click)="activeTab = 1"
        >
          <img
            [src]="
              activeTab === 1
                ? './assets/images/icon/dashboard_icon_active.svg'
                : './assets/images/icon/dashboard_icon_inactive.svg'
            "
          />
          Dashboard
        </div>
        <div
          class="tab"
          [class.active]="activeTab === 2"
          (click)="activeTab = 2"
        >
          <img
            [src]="
              activeTab === 2
                ? './assets/images/icon/info_active_icon.svg'
                : './assets/images/icon/info_inactive_icon.svg'
            "
          />
          Details
        </div>
        <div
          class="tab"
          [class.active]="activeTab === 3"
          (click)="activeTab = 3"
        >
          <img
            [src]="
              activeTab === 3
                ? './assets/images/icon/image_icon_active.svg'
                : './assets/images/icon/image_icon_inactive.svg'
            "
          />
          Gallery
        </div>
      </div>
    </div>

    @if(activeTab === 1 || activeTab === 2) {
    <div class="carts_row">
      <div class="overview_carts">
        <div *ngFor="let carts of row1" class="carts">
          <div class="upper_part">
            <div class="left_side">
              <div class="carts_description">{{ carts.carts_name }}</div>
            </div>
          </div>
          <div class="upper_part">
            <div class="total_amount">
              <span style="color: #0054a6" *ngIf="carts.isAmount">৳</span>
              {{ carts.calculated_data | number }}
            </div>
            <div class="right_side">
              <div class="carts_img">
                <img loading="lazy" [src]="carts.carts_icon" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overview_carts">
        <div *ngFor="let carts of row2" class="carts">
          <div class="upper_part">
            <div class="left_side">
              <div class="carts_description">{{ carts.carts_name }}</div>
            </div>
          </div>
          <div class="upper_part">
            <div class="total_amount">
              <span style="color: #0054a6" *ngIf="carts.isAmount">৳</span>
              {{ carts.calculated_data | number }}
            </div>
            <div class="right_side">
              <div class="carts_img">
                <img loading="lazy" [src]="carts.carts_icon" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="overview_carts">
        <div *ngFor="let carts of row3" class="carts last_row">
          <div class="upper_part">
            <div class="left_side">
              <div class="carts_description">{{ carts.carts_name }}</div>
            </div>
          </div>
          <div class="upper_part">
            <div class="total_amount">
              <span style="color: #0054a6" *ngIf="carts.isAmount">৳</span>
              {{ carts.calculated_data | number }}
            </div>
            <div class="right_side">
              <div class="carts_img">
                <img loading="lazy" [src]="carts.carts_icon" alt="" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="title_select_box">
      <div class="main_body_title">Campaigns Analytics</div>
      <dx-select-box
        [dataSource]="[
          { value: 'bar-chart', label: 'Donation' },
          { value: 'line-chart', label: 'Participating' }
        ]"
        displayExpr="label"
        valueExpr="value"
        [(value)]="selectedChart"
        (onValueChanged)="onChartChange($event)"
        class="chart-select"
      ></dx-select-box>
    </div>

    <div class="chart_donation_history">
      @if(selectedChart === 'line-chart'){
      <div class="chart-container">
        <div class="chart_header">
          <div class="abcd text-start">
            <div class="recent_donation">Employee Participating</div>
            <img
              loading="lazy"
              src="./assets/images/icon/trending_up_icon.svg"
              alt="Donor"
              title="donor"
              class="for_donor"
            />
            <span class="font_16 recent_donated_text"
              >{{ getTotalEmployees }} Participation in last
              {{ lineChartDays }}</span
            >
          </div>
          <dx-select-box
            [dataSource]="[
              { value: 'last15Days', label: 'Last Fortnight' },
              { value: 'lastWeek', label: 'Last Week' },
              { value: 'lastMonth', label: 'Last Month' }
            ]"
            displayExpr="label"
            valueExpr="value"
            [(value)]="selectedPeriod"
            class="chart-select second_chart"
            (onValueChanged)="onTimePeriodChange($event)"
          ></dx-select-box>
        </div>
        <dx-chart
          [dataSource]="lineChartData"
          [palette]="'extrapolate'"
          class="line_chart"
        >
          <dxi-series
            argumentField="formattedDate"
            valueField="total_employee"
            name="Amount Donated"
            type="line"
            color="#0054A6"
            width="5"
          >
          </dxi-series>
          <dxo-marker
            [visible]="true"
            color="#FFD602"
            size="20"
            shape="circle"
          ></dxo-marker>
          <dxo-common-series-settings
            argumentField="formattedDate"
            type="line"
          ></dxo-common-series-settings>
          <dxo-tooltip [enabled]="true"> </dxo-tooltip>
          <dxo-value-axis>
            <dxo-label> </dxo-label>
          </dxo-value-axis>
          <dxo-argument-axis
            [valueMarginsEnabled]="false"
            discreteAxisDivisionMode="crossLabels"
          >
            <dxo-grid [visible]="true"></dxo-grid>
          </dxo-argument-axis>
          <dxo-legend
            verticalAlignment="top"
            horizontalAlignment="center"
            position="outside"
            backgroundColor="none"
          >
          </dxo-legend>
          <dxo-export [enabled]="true"></dxo-export>
        </dx-chart>
      </div>
      }@else{
      <div class="chart-container">
        <div class="chart_header">
          <div class="abcd text-start">
            <div class="recent_donation">Donation Analytics</div>
            <img
              loading="lazy"
              src="./assets/images/icon/trending_up_icon.svg"
              alt="Donor"
              title="donor"
              class="for_donor"
            />
            <span class="font_16 recent_donated_text"
              >{{ getTotalAmount }} Total Collection in last
              {{ lineChartDays }}</span
            >
          </div>
          <dx-select-box
            [dataSource]="[
              { value: 'last15Days', label: 'Last Fortnight' },
              { value: 'lastWeek', label: 'Last Week' },
              { value: 'lastMonth', label: 'Last Month' }
            ]"
            displayExpr="label"
            valueExpr="value"
            [(value)]="selectedPeriod"
            class="chart-select second_chart"
            (onValueChanged)="onTimePeriodChange($event)"
          ></dx-select-box>
        </div>
        <dx-chart
          [barGroupWidth]="40"
          id="chart"
          class="chart_div"
          [dataSource]="donations"
        >
          <dxo-break-style line="waved"></dxo-break-style>
          <dxo-zoom-and-pan argumentAxis="both"></dxo-zoom-and-pan>
          <dxo-scroll-bar [visible]="true"></dxo-scroll-bar>
          <dxo-animation
            easing="linear"
            [duration]="500"
            [maxPointCountSupported]="300"
          >
          </dxo-animation>
          <dxo-legend
            verticalAlignment="top"
            horizontalAlignment="center"
            position="outside"
            backgroundColor="none"
          >
          </dxo-legend>
          <dxi-series
            argumentField="formattedDate"
            valueField="total_matched_amount"
            name="Matched"
            type="bar"
            color="#FFD602"
            barWidth="28"
            cornerRadius="0px"
            stack="sameGroup"
          >
          </dxi-series>
          <dxi-series
            argumentField="formattedDate"
            valueField="total_amount"
            name="Collected"
            type="bar"
            color="#0054a6"
            barWidth="28"
            cornerRadius="0px"
            stack="sameGroup"
          >
          </dxi-series>
          <!-- <dxo-argument-axis
            [visualRange]="{
              length: 0
            }"
          >
          </dxo-argument-axis> -->
          <dxo-common-series-settings
            argumentField="formattedDate"
            type="bar"
            hoverMode="allArgumentPoints"
            selectionMode="allArgumentPoints"
          >
          </dxo-common-series-settings>
        </dx-chart>
      </div>
      }
      <div class="donation_summary">
        <div class="donor-container">
          <div class="abcd text-start mt-4 mb-4 w-100">
            <div class="recent_donation">Recent Donation</div>
            <img
              loading="lazy"
              src="../../../../../assets/images/icon/trending_up_icon.svg"
              alt="Donor"
              title="donor"
              class="for_donor"
            />
            <span class="font_16 recent_donated_text"
              >{{ row1[0].calculated_data }} people just donated</span
            >
          </div>
          <div class="donor_list_container">
            <dx-button class="blue-button-md" style="width: 100%"
              >Donation</dx-button
            >
            <div *ngIf="donationSummary?.length > 0" class="donor_list">
              <div class="d-flex gap-2">
                <div class="donor_icon_div">
                  <img
                    src="../../../../../assets/images/icon/donor_icon_blue.png"
                    alt="Donor"
                    title="donor"
                  />
                </div>
                <div class="d-flex gap-2 flex-column">
                  <span class="font_18">{{ donationSummary[0]?.name }} </span>
                  <span class="font_16">
                    ৳{{ donationSummary[0]?.amount }}
                  </span>
                </div>
              </div>
            </div>

            <div *ngIf="donationSummary?.length > 1" class="donor_list">
              <div class="d-flex gap-2">
                <div class="donor_icon_div">
                  <img
                    src="../../../../../assets/images/icon/donor_icon_blue.png"
                    alt="Donor"
                    title="donor"
                  />
                </div>
                <div class="d-flex gap-2 flex-column">
                  <span class="font_18">{{ donationSummary[1]?.name }} </span>
                  <span class="font_16">
                    ৳{{ donationSummary[1]?.amount }}
                  </span>
                </div>
              </div>
            </div>
            <div *ngIf="donationSummary?.length > 2" class="donor_list">
              <div class="d-flex gap-2">
                <div class="donor_icon_div">
                  <img
                    src="../../../../../assets/images/icon/donor_icon_blue.png"
                    alt="Donor"
                    title="donor"
                  />
                </div>
                <div class="d-flex gap-2 flex-column">
                  <span class="font_18">{{ donationSummary[2]?.name }} </span>
                  <span class="font_16">
                    ৳{{ donationSummary[2]?.amount }}
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    } @if(activeTab === 3) {
    <app-campaign-gallery class="w-100"></app-campaign-gallery>
    <!-- <app-corporate-campaign-gallery class="w-100"></app-corporate-campaign-gallery> -->
    }
  </div>
</div>
