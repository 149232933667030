<div class="main_div">
  <div style="margin-bottom: 16px">
    <span class="colored">Home / </span> Account Settings
  </div>
  <div class="main_body_title" style="margin-bottom: 16px">Company Profile</div>
  <div class="completeness" style="margin-bottom: 16px">
    <div class="svg-container">
      <svg
        class="progress-ring svg_class"
        width="90"
        height="90"
        viewBox="0 0 90 90"
      >
        <circle
          cx="45"
          cy="45"
          r="39"
          fill="none"
          stroke="#b3cce4"
          stroke-width="12"
        />
        <circle
          cx="45"
          cy="45"
          r="39"
          fill="none"
          stroke="#0054a6"
          stroke-width="12"
          pathLength="100"
          stroke-dasharray="100"
          [attr.stroke-dashoffset]="100 - progress"
        />
      </svg>
      <div class="progress-text">{{ progress }}%</div>
    </div>
    <div class="title_subtitle">
      <div class="title_">Completeness of Company Profile (2/8)</div>
      <div class="desc">
        Last updated on: {{ profile?.updated_at | date : "dd MMM YYYY" }}
      </div>
    </div>
  </div>
  <div class="main_body">
    <div style="display: flex; flex-direction: column; gap: 32px">
      <div class="upper_part">
        <div class="title_img">
          <div class="img">
            <img
              class="img1"
              src="./assets/images/icon/organization_icon.svg"
              alt="pro_pic"
            />
            <img
              class="img2"
              src="./assets/images/icon/upload_camera.svg"
              alt="cam_icon"
            />
          </div>
          <div class="title_edit">
            <div class="title">{{ profile?.name }}</div>
            <div class="edit_mode">
              <img
                (click)="toggleEditMode()"
                class="cursor"
                [src]="
                  editMode
                    ? '/assets/images/icon/pencil-edit-blue.svg'
                    : '/assets/images/icon/pencil-edit.svg'
                "
              />
            </div>
          </div>
        </div>
        <div class="about_connected">
          <div class="about_title_desc">
            <div class="about">About Us</div>
            <div class="desc">
              <dx-text-area
                class="custom-fill border-none"
                [ngStyle]="editMode ? {} : { border: '1px solid white' }"
                placeholder="Write about your company"
                [height]="'220px'"
                [readOnly]="!editMode"
                [(ngModel)]="updateBody.about_us"
              >
                <dx-validator validationGroup="validationGrp">
                  <!-- <dxi-validation-rule
                    type="required"
                    message="phone no is required"
                  ></dxi-validation-rule> -->
                </dx-validator>
              </dx-text-area>
            </div>
          </div>
        </div>
      </div>
      <div class="lower_part">
        <div
          class="full_panel row d-flex justify-content-between align-items-center"
        >
          <div class="select_panel col d-flex p-0">
            <dx-tab-panel
              (onSelectionChanged)="onTabChange($event)"
              [selectedIndex]="activeTabIndex"
              style="box-shadow: none"
              [swipeEnabled]="true"
            >
              <dxi-item title="Company Information"> </dxi-item>
              <dxi-item title="CSR Preferences"> </dxi-item>
              <dxi-item
                title="Payment Information
                        "
              >
              </dxi-item>
              <dxi-item
                title="Additional Information
                        "
              >
              </dxi-item>
            </dx-tab-panel>
          </div>
        </div>
      </div>
      @if(activeTabIndex === 0){
      <div class="details_part">
        <div class="left_details">
          <div class="profile_name">
            <div class="profile_title">Company Address</div>
            <dx-text-area
              class="custom-fill"
              placeholder="Enter company address here"
              [maxLength]="500"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              [(ngModel)]="updateBody.address"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="this is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-text-area>
          </div>
          <div class="profile_name">
            <div class="profile_title">Website</div>
            <dx-text-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              placeholder="Enter your website"
              [readOnly]="!editMode"
              [(ngModel)]="updateBody.website"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="website is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Industry</div>
            <dx-text-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              placeholder="Enter your industry"
              [readOnly]="!editMode"
              [(ngModel)]="updateBody.industry"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="Date is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Contact Information</div>
            <dx-text-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              placeholder="Enter your phone"
              [readOnly]="!editMode"
              [(ngModel)]="updateBody.contact_no"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="phone no is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
        <div class="right_details">
          <div class="profile_name">
            <div class="profile_title">Email</div>
            <dx-text-box
              class="custom-fill"
              placeholder="Enter your email address"
              [ngStyle]="{ border: '1px solid white' }"
              [readOnly]="true"
              [ngModel]="profile.email"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="Email is required"
                >
                </dxi-validation-rule>
                <dxi-validation-rule
                  type="email"
                  message="Email is invalid. Ex: jone@gmail.com"
                >
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Number of employee</div>
            <dx-number-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              placeholder="Enter the number of employees"
              [showClearButton]="true"
              format="#,###"
              [min]="1"
              [readOnly]="!editMode"
              [(ngModel)]="updateBody.no_of_employees"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="Number is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-number-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Annual CRS Budget</div>
            <dx-number-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              placeholder="Enter the amount"
              [showClearButton]="true"
              format="#,###"
              [readOnly]="!editMode"
              [min]="1"
              [(ngModel)]="updateBody.annual_crs_budget"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="Budget amount is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-number-box>
          </div>
        </div>
      </div>
      } @if(activeTabIndex === 1){
      <div class="details_part">
        <div class="left_details">
          <div class="profile_name">
            <div class="profile_title">Preferred Donation Types</div>
            <dx-select-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              [dataSource]="['ONE', 'MONTHLY']"
              placeholder="Select..."
              [(ngModel)]="updateBody.donation_types"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="This is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Corporate Matching Ratio</div>
            <dx-number-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter Matching Ratio..."
              [showClearButton]="true"
              format="#,###"
              [min]="0"
              [(ngModel)]="updateBody.matching_ratio"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="This is required"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-number-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Total Company-Wide Matching Budget</div>
            <dx-number-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter corporate matching percentage"
              [showClearButton]="true"
              format="#,###"
              [min]="0"
              [(ngModel)]="updateBody.matching_budget"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="This is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-number-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Approval Requirement for Donations</div>

            <div class="switch_list">
              <div
                [ngClass]="
                  editMode
                    ? 'toggle-container cursor'
                    : 'toggle-container cursor'
                "
                (click)="
                  editMode
                    ? (updateBody.approval_requirement =
                        !updateBody.approval_requirement)
                    : null
                "
              >
                <img
                  [src]="
                    updateBody.approval_requirement
                      ? './assets/images/icon/toggle_active.svg'
                      : './assets/images/icon/toggle_inactive.svg'
                  "
                  width="31"
                  height="31"
                  alt="toggle_button"
                />
                <span>Yes/No</span>
              </div>
            </div>
          </div>
        </div>
        <div class="right_details">
          <div class="profile_name">
            <div class="profile_title">Eligible Charities Categories</div>
            <dx-select-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              [dataSource]="['Health Care', 'Edu Care', 'Firming Care']"
              placeholder="Select..."
              [(ngModel)]="updateBody.charities_categories"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="select an option"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-select-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Annual Matching Limit per Employee</div>
            <dx-number-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter annual matching budget. "
              [showClearButton]="true"
              format="#,###"
              [min]="0"
              [(ngModel)]="updateBody.matching_limit"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="This is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-number-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">
              Minimum Donation Amount for Matching
            </div>
            <dx-number-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Define the minimum amount for matching eligibility. "
              [showClearButton]="true"
              format="#,###"
              [min]="0"
              [(ngModel)]="updateBody.minimum_amount"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="This is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-number-box>
          </div>
        </div>
      </div>
      }@if(activeTabIndex === 2){
      <div class="details_part">
        <div class="left_details">
          <div class="profile_name">
            <div class="profile_title">Billing Address</div>
            <dx-text-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter billing address"
              [(ngModel)]="updateBody.billing_address"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="This is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Select Payment Method</div>
            <dx-select-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              [dataSource]="['Bkash', 'Nogod', 'Card']"
              placeholder="Select Payment Method..."
              [(ngModel)]="updateBody.payment_type"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="select an option"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-select-box>
          </div>
        </div>
        <div class="right_details last_right">
          <div class="profile_name">
            <div class="profile_title">Billing Contact Name</div>
            <dx-text-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter Billing Contact Name"
              [(ngModel)]="updateBody.billing_contact_name"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="This is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-text-box>
          </div>
          <div class="profile_name">
            <div class="profile_title">Billing Contact Email Address</div>
            <dx-text-box
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter Billing Contact Email Address"
              [(ngModel)]="updateBody.billing_contact_email"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="Email is required"
                >
                </dxi-validation-rule> -->
                <dxi-validation-rule
                  type="email"
                  message="Email is invalid. Ex: jone@gmail.com"
                >
                </dxi-validation-rule>
              </dx-validator>
            </dx-text-box>
          </div>
        </div>
      </div>
      }@if(activeTabIndex === 3){
      <div class="details_part">
        <div class="left_details">
          <div class="profile_name">
            <div class="profile_title">Where Did you hear about us</div>
            <dx-select-box
              class="input_field"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              [dataSource]="[
                'SOCIAL',
                'GOOGLE',
                'FRIENDS',
                'ADVERTISEMENT',
                'OTHERS'
              ]"
              placeholder="Select a Type..."
              [(ngModel)]="updateBody.found_us"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule
                  type="required"
                  message="select an option"
                ></dxi-validation-rule> -->
              </dx-validator>
            </dx-select-box>
          </div>
        </div>
        <div class="right_details last_right">
          <div class="profile_name">
            <div class="profile_title">Enter any additional Information</div>
            <dx-text-area
              class="custom-fill"
              [ngStyle]="editMode ? {} : { border: '1px solid white' }"
              [readOnly]="!editMode"
              placeholder="Enter additional Information"
              [height]="125"
              [maxLength]="500"
              [(ngModel)]="updateBody.additional_info"
            >
              <dx-validator validationGroup="validationGrp">
                <!-- <dxi-validation-rule type="required" message="this is required">
                </dxi-validation-rule> -->
              </dx-validator>
            </dx-text-area>
          </div>
        </div>
      </div>
      }
    </div>
    <div class="button_container">
      <dx-button
        *ngIf="editMode"
        class="blue-button-lg"
        (onClick)="updateProfile()"
        >Save</dx-button
      >
    </div>
  </div>
</div>
