import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DevExtremeModule, DxCircularGaugeModule } from 'devextreme-angular';
import { FormsModule } from '@angular/forms';
import { SharedModule } from 'src/app/shared/modules/shared.module';
import { CorporateCampaignListComponent } from './corporate-campaign/corporate-campaign-list/corporate-campaign-list.component';
import { CorporateRoutingModule } from './corporate-routing.module';
import { CorporateMenuComponent } from 'src/app/layout/logged-in-layout/corporate-menu/corporate-menu.component';
import { CorporateCreateCampaignComponent } from './corporate-campaign/corporate-create-campaign/corporate-create-campaign.component';
import { CorporateEmployeeManagementComponent } from './corporate-employee-management/corporate-employee-management.component';
import { CorporateInviteEmployeeComponent } from './corporate-invite-employee/corporate-invite-employee.component';
import { CorporateCreateEmployeeAccountComponent } from './corporate-create-employee-account/corporate-create-employee-account.component';
import { CorporateCreateCampaignParentComponent } from './corporate-campaign/corporate-create-campaign-parent/corporate-create-campaign-parent.component';
import { CorporateCampaignDashboardComponent } from './corporate-campaign/corporate-campaign-dashboard/corporate-campaign-dashboard.component';
import { CorporateCompanyProfileComponent } from './corporate-company-profile/corporate-company-profile.component';
import { EmployeeEngagementComponent } from '../corporate/employee-engagement/employee-engagement.component';
import { CorporateDashboardComponent } from '../corporate/corporate-dashboard/corporate-dashboard.component';
import { CorporateDonorInformationComponent } from './coporate-donor-information/corporate-donor-information.component';

@NgModule({
  declarations: [
    CorporateMenuComponent,
    CorporateCampaignListComponent,
    CorporateCreateCampaignComponent,
    CorporateEmployeeManagementComponent,
    CorporateInviteEmployeeComponent,
    CorporateCreateEmployeeAccountComponent,
    CorporateCreateCampaignParentComponent,
    CorporateCampaignDashboardComponent,
    CorporateCompanyProfileComponent,
    EmployeeEngagementComponent,
    CorporateDonorInformationComponent,
    CorporateDashboardComponent,
  ],
  imports: [
    CorporateRoutingModule,
    CommonModule,
    DevExtremeModule,
    FormsModule,
    SharedModule,
  ],
})
export class CorporateModule {}
