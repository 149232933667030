import { CorporateProfileService } from './../../../shared/services/corporate/corporate-profile/corporate-profile.service';
import { AuthService } from 'src/app/shared/services/auth/auth.service';
import { Component, OnInit } from '@angular/core';
import { SignupBodyModel } from 'src/app/shared/model/auth/signup.model';
import { LocalStorageService } from 'src/app/shared/services/local-storage/local-storage.service';
import ValidationEngine from 'devextreme/ui/validation_engine';
import { CorporateTypeEnum } from 'src/app/shared/enum/corporate-type.enum';
import { NgxSpinnerService } from 'ngx-spinner';
import { CorporateEmployeeModel } from 'src/app/shared/model/corporate/corporate-employee.model';
import { CorporateDepartmentModel } from 'src/app/shared/model/corporate/corporate-department.model';
import { UtilitiesService } from 'src/app/shared/services/utilities/utilities.service';
import { Router } from '@angular/router';

@Component({
  selector: 'app-corporate-create-employee-account',
  templateUrl: './corporate-create-employee-account.component.html',
  styleUrl: './corporate-create-employee-account.component.scss',
})
export class CorporateCreateEmployeeAccountComponent implements OnInit {
  public allFundraisers = [
    {
      slNo: 1,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 2,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 3,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 4,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 5,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 6,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 7,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 8,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 9,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 10,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
    {
      slNo: 11,
      donor_id: '40f82a91-26b1-4a1f-8554-5c03f00504f7',
      donorName: 'Maksudur Rahman',
      phoneNumber: '01786062801',
      transactionId: '71NXMA9X',
      paymentMethod: 'Bank Transfer',
      updated_at: '2025-01-02T18:56:22.193Z',
      amount: 10000,
      campaign: 'Student Welfare Relief Fund - 2024',
      status: 'Pledged',
    },
  ];

  public showCredentials: boolean = false;
  public updatedAt: Date;

  public departments: any[] = [];
  public selectedDepartment: any;

  public employeeBody: SignupBodyModel = new SignupBodyModel();
  public employee: CorporateEmployeeModel = new CorporateEmployeeModel();

  public confirmPassword: string = null;
  public isPasswordVisible = false;
  public isConfirmPasswordVisible = false;
  public passwordStrengthMessage: string = '';

  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly spinner: NgxSpinnerService,
    private readonly utilitiesService: UtilitiesService,
    private readonly localStorageService: LocalStorageService,
    private readonly corporateProfileService: CorporateProfileService
  ) {}

  ngOnInit(): void {
    this.employeeBody.company_id =
      this.localStorageService.getSessionUser().company_id;
    this.employeeBody.company_name =
      this.localStorageService.getSessionUser().company_name;
    this.employeeBody.corporate_type = CorporateTypeEnum.EMPLOYEE;

    this.employee = new CorporateEmployeeModel(this.employeeBody);

    this.getDepartments();
  }

  private getDepartments(): void {
    this.corporateProfileService
      .getDepartments(this.employeeBody.company_id)
      .subscribe((result: any) => {
        this.departments = result;
      });
  }

  public async addDepartment(e: any) {
    const name = e?.text.toLowerCase();
    const dept = this.departments.find(
      (dep) => dep.department_name.toLowerCase() === name
    );

    if (dept) {
      this.selectedDepartment = dept;
      this.employee.department_id = this.selectedDepartment.id;
      this.employee.department_name = this.selectedDepartment.department_name;
    } else {
      const response = await this.confirmAction(
        'You want to Add the department?',
        'Add'
      );

      if (response.isConfirmed) {
        this.selectedDepartment = new CorporateDepartmentModel(
          this.employeeBody
        );
        this.selectedDepartment.department_name = e?.text;
        this.selectedDepartment.department_description = e?.text;

        this.createDepartment();
      } else {
        this.selectedDepartment = null;
        this.employee.department_id = null;
        this.employee.department_name = null;
      }
    }
  }

  private createDepartment(): void {
    this.corporateProfileService
      .createDepartment(this.selectedDepartment)
      .subscribe((result: any) => {
        this.getDepartments();
        this.selectedDepartment = result;
      });
  }

  public createEmployeeAccount(): void {
    const { isValid } = ValidationEngine.validateGroup('validationGrp');
    if (isValid) {
      this.spinner.show();
      this.authService.corporateSignup(this.employeeBody).subscribe({
        next: (result) => {
          this.employee = new CorporateEmployeeModel(result?.user);
          this.employee.user_id = result?.user?.id;
          this.employee.company_id = this.employeeBody.company_id;
          this.employee.department_id = this.selectedDepartment.id;
          this.employee.department_name = this.selectedDepartment.department_name;

          this.createEmployee();
        },
        error: (err) => {
          this.spinner.hide();
        },
      });
    }
  }

  public createEmployee(): void {
    this.corporateProfileService.createEmployee(this.employee).subscribe({
      next: (result) => {
        this.spinner.hide();
        this.updatedAt = result.joining_date;
        this.showCredentials = true;
        this.confirmPassword = '';
      },
      error: (err) => {
        this.spinner.hide();
      },
    });
  }

  public passwordComparison = () => this.employeeBody.password;

  public onPasswordChanged(e: any) {
    this.passwordStrengthMessage = this.getPasswordStrength(
      e.event.target.value
    );
  }

  public getPasswordStrength(password: string): string | null {
    const hasUpperCase = /[A-Z]/.test(password);
    const hasLowerCase = /[a-z]/.test(password);
    const hasNumeric = /\d/.test(password);
    const hasSpecialChar = /[@$!%*?&]/.test(password);
    const isValidLength = password.length >= 8;

    if (
      !isValidLength ||
      !hasUpperCase ||
      !hasLowerCase ||
      !hasNumeric ||
      !hasSpecialChar
    ) {
      return 'Password is too weak';
    }

    return null;
  }

  public togglePasswordVisibility = () => {
    this.isPasswordVisible = !this.isPasswordVisible;
  };

  public toggleConfirmPasswordVisibility = () => {
    this.isConfirmPasswordVisible = !this.isConfirmPasswordVisible;
  };

  private async confirmAction(message: string, confirmButtonText: string) {
    return await this.utilitiesService.showSwalAndReturn({
      type: 'warning',
      title: 'Are you sure?',
      message: message,
      confirmButtonText: confirmButtonText,
      showCancelButton: true,
      cancelButtonText: 'Cancel',
    });
  }

  public closeModal(){
    ValidationEngine.resetGroup('validationGrp');
    this.showCredentials = false;
  }
}
