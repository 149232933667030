<div class="main_div">
  <div>
    <span class="fs_16 c_0054a6">Home / Employee Management / </span>
    <span class="fs_16">Create Account</span>
  </div>

  <div>
    <div class="title">Create Employee Account</div>
    <div class="fs_18 c_686868 mt-1" style="width: 600px">
      Generate accounts for your employees, enabling them to join and contribute
      to your corporate giving initiatives.
    </div>
  </div>

  <div class="w-100 d-flex justify-content-center">
    <div class="form_body">
      <label class="label requiredMark"> Employee Name </label>
      <dx-text-box
        class="custom-fill"
        [placeholder]="'Enter employee name'"
        [tabIndex]="1"
        [(ngModel)]="employeeBody.name"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule
            type="required"
            message="Name is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <label class="label requiredMark"> Department </label>
      <dx-select-box
        class="custom-fill"
        [tabIndex]="2"
        [dataSource]="departments"
        [(value)]="selectedDepartment"
        displayExpr="department_name"
        placeholder="Select or add new department"
        [acceptCustomValue]="true"
        (onCustomItemCreating)="addDepartment($event)"
        [showClearButton]="true"
        [searchEnabled]="true"
        [searchTimeout]="100"
        [showDropDownButton]="true"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule
            type="required"
            message="Department is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-select-box>
      <label class="label requiredMark"> Employee Email </label>
      <dx-text-box
        class="custom-fill"
        placeholder="Enter employee email"
        [tabIndex]="3"
        [(ngModel)]="employeeBody.email"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule
            type="email"
            message="Email is invalid. Ex: jone@gmail.com"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="required"
            message="Email is required"
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <label class="label requiredMark"> Create New Password </label>
      <dx-text-box
        class="custom-fill"
        placeholder="Enter your password"
        [tabIndex]="4"
        [(ngModel)]="employeeBody.password"
        [mode]="isPasswordVisible ? 'text' : 'password'"
        [inputAttr]="{ 'aria-label': 'Password' }"
        (onInput)="onPasswordChanged($event)"
        [buttons]="[
          {
            name: 'togglePassword',
            location: 'after',
            options: {
              icon: 'eye',
              onClick: togglePasswordVisibility,
              elementAttr: {
                class: 'password-toggle ' + (isPasswordVisible ? 'visible' : '')
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule type="required" message="Password is required">
          </dxi-validation-rule>
          <dxi-validation-rule
            *ngIf="employeeBody.password != confirmPassword"
            type="required"
            message="Passwords doesn't match"
          >
          </dxi-validation-rule>
          <dxi-validation-rule
            type="pattern"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Passwords must be at least 8 characters long and contain a mix of upper and lower case letters, numbers, and special characters(@$!%*?&)."
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <label class="label requiredMark"> Confirm Your Password </label>
      <dx-text-box
        class="custom-fill"
        [tabIndex]="5"
        [(ngModel)]="confirmPassword"
        placeholder="Re-enter your password"
        [mode]="isConfirmPasswordVisible ? 'text' : 'password'"
        [inputAttr]="{ 'aria-label': 'Password' }"
        [buttons]="[
          {
            name: 'togglePassword',
            location: 'after',
            options: {
              icon: 'eye',
              onClick: toggleConfirmPasswordVisibility,
              elementAttr: {
                class:
                  'password-toggle ' +
                  (isConfirmPasswordVisible ? 'visible' : '')
              }
            }
          }
        ]"
      >
        <dx-validator validationGroup="validationGrp">
          <dxi-validation-rule
            type="required"
            message="Confirm your password"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="compare"
            [comparisonTarget]="passwordComparison"
            message="Password and Confirm Password do not match"
          ></dxi-validation-rule>
          <dxi-validation-rule
            type="pattern"
            pattern="^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$"
            message="Passwords must be at least 8 characters long and contain a mix of upper and lower case letters, numbers, and special characters(@$!%*?&)."
          ></dxi-validation-rule>
        </dx-validator>
      </dx-text-box>
      <button
        class="blue-button-md"
        style="width: 100%; margin-top: 24px"
        (click)="createEmployeeAccount()"
      >
        Create Account
      </button>
    </div>
  </div>

  <!-- <div class="title">Account History</div>

  <div>
    <dx-data-grid
      class="donor_list_data_grid"
      [dataSource]="allFundraisers"
      [showBorders]="false"
      [showColumnLines]="false"
      [showRowLines]="false"
      [allowColumnResizing]="true"
      columnResizingMode="widget"
      [focusedRowEnabled]="false"
      [rowAlternationEnabled]="false"
      rowTemplate="rowDonationList"
      [wordWrapEnabled]="true"
      [columnAutoWidth]="true"
    >
      <dxo-paging [pageSize]="10" [pageIndex]="0"> </dxo-paging>
      <dxo-pager
        *ngIf="allFundraisers?.length > 10"
        [visible]="true"
        [showPageSizeSelector]="false"
        [allowedPageSizes]="[10]"
        [showInfo]="false"
        [showNavigationButtons]="true"
      >
      </dxo-pager
      ><dxi-column caption="Date & Time" dataField="slNo" alignment="left">
      </dxi-column>
      <dxi-column
        caption="Employee Name"
        dataField="donorName"
        alignment="left"
      ></dxi-column>
      <dxi-column
        caption="Department"
        dataField="transactionId"
        alignment="left"
      >
      </dxi-column>
      <dxi-column caption="Email Address" dataField="date" alignment="left">
      </dxi-column>

      <tbody class="bg-white" *dxTemplate="let row of 'rowDonationList'">
        <tr style="display: contents !important">
          <td>
            <div class="profile-details">
              <div class="donor-name">
                {{ row?.data?.updated_at | date : "dd-mm-yyyy" }}
              </div>
              <div class="donor-email">
                {{ row?.data?.updated_at | date : "hh:mm a" }}
              </div>
            </div>
          </td>
          <td>
            <div class="donor-profile row_white">
              <img
                class="profile_img"
                loading="lazy"
                [src]="
                  row?.data?.profile_img || './assets/images/round_profile.png'
                "
                alt=""
              />
              <div class="donor-name">
                {{ row?.data?.donorName }}
              </div>
            </div>
          </td>
          <td>
            <div class="donor-name">{{ "Product Development" }}</div>
          </td>
          <td>
            <div class="donor-name">
              {{ "mahmudulhasan@gmail.com" }}
            </div>
          </td>
        </tr>
      </tbody>
    </dx-data-grid>
  </div> -->
</div>

<dx-popup
  class="donor_activity"
  [(visible)]="showCredentials"
  [width]="1000"
  [height]="600"
  [showTitle]="true"
  [showCloseButton]="true"
  [dragEnabled]="false"
  [resizeEnabled]="false"
  position="center"
  [enableBodyScroll]="false"
  [hideOnOutsideClick]="false"
  (onHiding)="closeModal()"
>
  <div class="h-100">
    <div class="w-100 title text-center mb-7 c_0054a6">
      Employee account created successfully!
    </div>

    <div class="d-flex gap-3 my-5 px-5">
      <div class="d-flex flex-column align-items-center w-50 mt-5">
        <div>
          <div class="">
            <div class="title fw-normal">Employee Name</div>
            <div class="title mt-2">{{ employeeBody?.name }}</div>
          </div>
          <div class="mt-5">
            <div class="title fw-normal">Account Password</div>
            <div class="title mt-2">{{ employeeBody?.password }}</div>
          </div>
        </div>
      </div>

      <div class="d-flex flex-column align-items-center w-50 mt-5">
        <div>
          <div class="">
            <div class="title fw-normal">Email Address</div>
            <div class="title mt-2">{{ employeeBody?.email }}</div>
          </div>
          <div class="mt-5">
            <div class="title fw-normal">Account Created</div>
            <div class="title mt-2">
              {{ updatedAt | date : "MMM dd, yyyy | hh:mm a" }}
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 d-flex w-100 justify-content-center gap-5">
      <div class="fs_18 c_686868">
        An email has been sent to the "{{ employeeBody?.email }}" with necessary
        credentials.
      </div>
      <!-- <button class="yellow-button-md mt-5" style="width: 280px">Close</button>
      <button class="mt-5 blue-button-md" style="width: 280px">
        Send Email
      </button> -->
    </div>
  </div>
</dx-popup>
